.paragraph-element {

    .image {
        padding-right: rem(16px);
    }
    ul, ol {
        // margin-left: rem(22px);
        // margin-bottom: rem(22px);
        margin: rem(22px);
    }
}


@media only screen and (max-width: large-break-point) { 
    .paragraph-element {
        .image {
            padding: 0;
        }
        
        .paragraph {
            margin-top: rem(16px);
        }
    }
}
