.caption-block, .caption-block p {
        position:relative;
        top: rem(5px);
        font-size: rem(15px);
        color: #717271;
        font-weight: normal;
	    font-style: normal;
}

.caption-block&:before {
        content: '';
        position: absolute;
        border-top: 1px solid border-color;
        width: rem(58px);
    }


    @media only screen and (max-width: large-break-point) {
        .caption-block, .caption-block p {
                font-size: rem(12px);
        }
}
