@keyframes border {
    from {border-color:foldable-orange;}
    to {border-color:foldable-orange-hover;}
}
@keyframes svgcolor {
    from {fill:foldable-orange;}
    to {fill:foldable-orange-hover;}
}

@keyframes border-fl {
    from {border-color:foldable-green;}
    to {border-color:foldable-green-hover;}
}
@keyframes background-fl {
    from {fill:foldable-green-hover;}
    to {fill:foldable-green-hover;}
}

.foldable-element{

	padding-bottom: 1.25rem;

	>.expand{
		width: 75px;
		height: 45px;
	    cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;

        &:hover {
            > svg {
                fill:foldable-orange-hover;
            } 

            > .arrow {
                background-image: url( '../images/icon-arrow-down-hover.svg' );
            }

            &.open { 
                > .arrow {
                    background-image: url( '../images/icon-arrow-up-hover.svg' );
                }
            }
        }

        > svg {
            fill: foldable-orange;
        }
        
        &.open {
            svg {
                animation-name: svgcolor;
                animation-duration: 1s;
            }

            > .arrow {
                background-image: url( '../images/icon-arrow-up.svg' );
            }

        }  

        &.fl-content {
            > svg {
                fill: foldable-green;
            }
            
            &:hover {
                > svg {
                    fill: foldable-green-hover;
                }
            }

            &.open {
                > svg {
                    animation-name: background-fl;
                    animation-duration: 1s;
                }
            }

        } 

        > .arrow {
            background-image: url( '../images/icon-arrow-down.svg' );
            margin-left: rem( 10px );
            width: 20px;
            height: 12px;
        }
	}
    
    >.element-container {
        position: relative;
        top: 20px;
    
        >.elements {
            > * {
                margin-left: 30px;
            }
            border-left: 5px solid foldable-orange-hover;
            
            @media( max-width: 768px ){

                > * {
                    margin-left: 0px;
                }

            	border-left: none;
            	padding: 0;
            	margin: 0;
            }
            
            animation-name: border;
            animation-duration: 1s;
            margin:-4px -30px 30px 0px;
            >.element:first-child {
                padding-top:0;
            }
            >.element {
                >*:first-child {
                    padding-top:0;
                }
            }
            
        }

        &.fl-content-container {
            > .elements {
                border-left: 5px solid foldable-green-hover;
                animation-name: border-fl;

                @media( max-width: 768px ){ 
                    border: none;
                }
            }
        }


		& img.responsive {
	   		width: 100%;
	   	}
    }

    > .element-container > .elements > .chapter-element {
        padding-bottom: 0;
        padding-top: 0;

        p {
            padding-bottom: 0;
        }
    }
}

.explanation{
	padding-top: 0;
	>.expand{
		transform: translateX( 0px );
		margin-right: 12px;
	}
}