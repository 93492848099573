.lernbuch-content {
    position: relative;
    //top: rem(60px);
    min-height: calc(100vh - 71px - 60px);

    .chapter-element {
        padding-bottom: rem(20px);
        page-break-inside: avoid;

        ul, ol {
            padding-left: 21px;
        }
    }

    h1.chapter-element {
       padding-bottom: rem( 30px ); 
       padding-top: rem( 30px );
    }
}


@media only screen and (max-width: small-break-point) {
    .lernbuch-content {
        min-height: calc(100vh - (71px + 120px));
        top: rem(120px);

        .chapter-element {
            padding-bottom: rem(15px);
        }
    }
}
