
h1, h2, h3, h4, h5, h6, p, body, blockquote {
    font-family: "Helvetica Neue LT", "Helvetica Neue", Helvetica, Arial, sans-serif; 
    font-style: normal;
    font-weight: 500;
    word-break: normal;
}

h1 {
    font-weight: bold;
    font-size: rem(58px);
    color: text-color;
    line-height: rem(70px);
    letter-spacing: rem(1.2px);
}

h2 {
    font-size: rem(48px);
    color: text-color;
    line-height: rem(58px);
}

h3 {
    font-size: rem(30px)
    letter-spacing: rem(1.25px)
    line-height: 46px
}

a {
    color: main-color;
}
a:hover {
    color: text-color;
}

ul, ol {
    padding: rem( 10px ) 0 rem( 10px ) rem( 20px );

    li + li {
        padding-top: rem( 5px );
    }
}

blockquote {
    font-size: rem(36px); 
    line-height: rem(50px);
    padding-bottom: 10px;
    color: brand-second-color
    margin-left: -70px;

    & + .caption {
        color: brand-second-color
        margin-left: -70px;
        font-size: rem(15px)
        line-height: rem(21px)
    }
}

@media only screen and (max-width: theme.settings.breakpoints.small ) {

    h1 {
        font-size: rem(36px);
        line-height: rem(42px);
    }

    h2 {
        font-size: rem(30px);
        line-height: rem(34px);
    }

    h3 {
        font-size: rem(24px)
        line-height: rem(28px);
    }
}