.fixed-page-layout.-lernspur {
	background-color: #202023;

	> .content {
		height: 100vh;

		&.-margin-right {
			margin-right: rem( 383px );
		}
	}

	> .sidebar {
		width: rem( 383px );

		.fresh {
			display: none;
		}
	}
}