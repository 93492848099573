.badge-counter {
    display: inline-block;
    margin-left: rem(10px);
    background-color: main-color;
    text-align: center;
    color: white;
    border-radius: rem(18px / 2);
    width: rem(18px);
    height: rem(18px);
    line-height: rem(18px);
    font-size: rem(10px);
    font-weight: normal;
    transform: translateY(-3.5px); // should be 0 minus half the difference between the `font-size`s
}
