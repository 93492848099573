.feedback-form {
    position: relative
    top: 100px
    z-index: 999999
    background-color: white
    width: 600px
    margin: auto
    box-shadow: 0px 1px 7px grey
    padding 20px
    border-radius: 10px

    > .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        cursor: pointer;
    }

}

@media only screen and (max-width: small-break-point) {
    .feedback-form {
        width: 100%;
    }
}