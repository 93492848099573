/***
 * Gallery Element:
 */
 
/**
 * Gallery:
 * `.gallery` - The styling for a gallery
 * 
 * 	@example
 * 	<div class="gallery visible"><div class="gallery__image-container"><div class="gallery__image fade-in"><img class="responsive" src="http://localhost/lernbuch-3/public/thumb/12/800x800.png" alt="Folie 3" title="Folie 3"><div class="caption caption--gallery abs">Folie 3</div></div></div><div class="button button--next gallery__button gallery__button--next rotate-270 right abs vertical_middle lb-icon-arrow"></div><div class="button button--prev gallery__button gallery__button--prev rotate-90 left abs vertical_middle lb-icon-arrow"></div><div class="pagination pagination--gallery abs bottom"><div class="pagination__element pagination__element--active"></div><div class="pagination__element"></div><div class="pagination__element"></div></div></div>
 */
 

.gallery-image-container {
    > .item {
        position: absolute;
        justify-content: space-between;
        height: 100%;
        width: 100%;

        > .prev, .next {
            display: flex;
            width: 50%;
            height: 100%;
        }

        > .prev {
            margin-left: rem(10px);
            justify-content: flex-start;
        }

        > .next {
            margin-right: rem(10px);
            justify-content: flex-end;
        }
    }
}


 .image-gallery {
	width: 100%;
	position: relative;
    display: flex;
    align-items: center;

    > .gallery-image-container {
        width: 100%;
        display: flex;

        .gallery-image {

            .responsive {
                max-width: 100%;
                min-width: 100%;
            }

            .caption, .caption p {
                font-size: rem(15px)
                line-height: rem(21px)
                padding-bottom: rem(10px);
                position: relative;
                top: 4px;
            }
        }
    }
 }
 
/**
 * Gallery Image Caption:
 * `.caption--gallery` - Definitions for the image caption
 * 
 * 	@example
 * 	div.caption.caption--gallery caption
 */
.caption--gallery {
	width: 100%;
	padding-top: 2px;
	bottom: -15px;
}
 
 /**
 * Gallery Button:
 * `.gallery__button` - Definitions for a general Gallery button
 * 
 * 	@example
 * 	div.button.button--prev.gallery__button.gallery__button--prev.lb-icon-arrow
 * 	
 */
.gallery-button {
	color: text-header-color;	
	width: 32px;
	height: 32px;
	padding: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.prev {
        // left: 0;
        > img {
            height: 23px;
        }
    }

    &.next {
        // right: 0;
        > img {
            height: 23px;
        }
    }
    
}

.gallery-button:hover {
	color: text-color;	
	border: none;
}

.gallery-button:before {
	top: 25px;
  	position: relative;
}

.pagination--gallery {
	bottom: -50px;
	text-align: center;
	padding: 0;
}

.gallery-navigation {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.gallery-element {
	
	width: 100%;
	//background-color: #000;


	> .prev{
		transform: rotate( 180deg );
	}

}

.gallery-image {
	
	margin: 0 auto rem( 10px ) auto;
	display: table;
    width: 100%;

	&.-hidden {
		display: none;
	}

    > img {
        max-height: rem( 500px );
        width: 100%;
    }
	
}
