/*
---
name: .tooltip-container
category: Components/Tooltip
tag:
 - component
 - tooltip
compile: true
---

A generic styling for a tooltip that is placed absolute and has a position: left, right, top, bottom

```jade
div.tooltip-container
	div.content
		Content
```
*/
.tooltip-container {

	position: absolute;
	padding: 12px;
	z-index: 1000;

	> .content {
		background-color: rgba( 0, 0, 0, 0.8 );
		border-radius: 3px;
		color: #ddd;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			width: 0; 
			height: 0;
			border: solid transparent;
			border-width: 6px;
		}
	}

	&.-left {
		transform: translate( -100%, -50% );

		> .content:before {
			border-left-color: rgba( 0, 0, 0, 0.8 );
			left: 100%;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&.-right {
		transform: translateY( -50% );

		> .content:before {
			border-right-color: rgba( 0, 0, 0, 0.8 );
			right: 100%;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&.-top {
		transform: translate( -50%, -100% );

		> .content:before {
			border-top-color: rgba( 0, 0, 0, 0.8 );
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&.-bottom {
		transform: translateX( -50% );

		> .content:before {
			border-bottom-color: rgba( 0, 0, 0, 0.8 );
			bottom: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}