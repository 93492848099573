.glossary-letters {
    padding-top: rem(5px);
    padding-bottom: rem(5px);
    padding-left: rem(24px);
    padding-right: rem(24px);
    border-radius: rem(27px);
    background-color: action-color;
    a {
        color: body-color;
        padding-right: rem(13px);
    }
}