.button-style {
    height: rem(40px);
    border-radius: rem(20px);
    border: none;
    margin: rem(10px) 0;
    text-align: center;
    text-transform: uppercase;
    padding-left: rem(10px);
    padding-right: rem(10px);
    font-size: rem(16px);
    cursor: pointer;

    &:hover {
        background-color: action-color-hover;
        color: body-color;
    }
    &.selected {
        background-color: action-color;
        color: body-color;
    }

    &.-main {
        background-color: action-color
        color: body-color

        &:hover {
            background-color: action-color-hover;
            color: body-color;
        }
        &.selected {
            background-color: action-color;
            color: body-color;
        }
    }

    &.-disabled {
        background-color: text-inactive-color
        color: text-color

        &:hover, &.selected {
            background-color: text-inactive-color;
            color: text-color;
        }
    }

}

@media only screen and (max-width: small-break-point) {
    .button-style {
        margin: rem(5px) 0;
    }
}