.text-input {
    height: rem(40px);
    border-radius: rem(20px);
    border: 1px solid #ccc;
    padding-left: rem(15px); 
    color: text-header-color;
    font-size: rem(16px);
    outline: none;

    &.-search {
        padding-left: rem(35px);
        width: rem(200px);
    }
}

@media only screen and (max-width: small-break-point) {
    .text-input {
        &.-search {
            font-size: rem(16px);
            width: rem(150px);
        }
    }
}