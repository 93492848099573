.main-mobileheader {
    position: absolute;
    top: 60px;
    border-top: 1px solid @border-color;

    .hamburger-mobile {
        position: absolute;
        top: rem(17px);
        left: rem(10px);
        cursor: pointer;
    }
}