.themenwaehler-iframe {
    width: 100vw; 
    top: rem(60px);
    position: relative;
    min-height: calc(100vh - 71px - 60px);

    &.-animation {
        animation: fadeInThemenwaehler 1s forwards;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-delay: 4.5s;
    }

    @media only screen and (max-width: small-break-point) {
        top: rem(120px);
    }

    @keyframes fadeInThemenwaehler {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

}