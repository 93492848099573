/**
 * Overwrites for the LernBuch
 */
/*
---
name: Colors
category: Shared/Variables
template: colors
tag:
 - variables
 - colors
colors:
 - variable: "text-color"
   description: "The default text color on the body"
   value: "#3F3F40"
 - variable: "text-inverted-color"
   description: "The default inverted text color on the body"
   value: "#FFF"
 - variable: "action-color"
   description: "The main action color used for links and buttons"
   value: "#EC6449"
 - variable: "body-color"
   description: "The main background color"
   value: "#FFF"
 - variable: "enabled-color"
   description: "Signalcolor for enabled/ok/success/true - search for a better name"
   value: "#7CAE7A"
 - variable: "disabled-color"
   description: "Signalcolor for disabled/notok/error/false - search for a better name"
   value: "#EC6449"
 - variable: "outline-navigation-color"
   description: "Color for the elements in the outline navigation"
   value: "lighten( #3F3F40, 60 )"
---

The color variables that are used throughout the library. 
*/
/*
---
name: Global settings
category: Shared/Variables
template: variables
tag:
 - variables
variables:
 - name: "text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "font-family"
   description: "The font-family of the body"
   default: "'Helvetica', sans-serif"
 - name: "text-rendering"
   description: "The text-rendering of the body"
   default: "optimizeLegibility"
 - name: "transition-duration"
   description: "The duration of the slide transitions"
   default: "0.4s"
 - name: "border-radius"
   description: "The default radius for borders"
   default: "2px"
---

These variables setups some global text formatting and styling.
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  vertical-align: baseline;
}
:root {
  font-size: 16px;
}
html {
  font-size: 100%;
}
body {
  line-height: 1.5rem;
  color: #202023;
  font-family: 'Roboto' , serif;
  text-rendering: optimizeLegibility;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
select {
  vertical-align: middle;
}
textarea {
  width: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
a {
  text-decoration: none;
  color: #3031fe;
}
img {
  display: block;
}
ul,
ol {
  margin-left: 1.25rem;
}
/*
---
name: .border-box
category: Box/Border
tag:
 - component
 - border
compile: true
---

A box to control borders on all four sides.

```jade
div.border-box.-left Some demo content
```

```jade
div.border-box.-all Some demo content
```
*/
.border-box.-top {
  border-top-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-right {
  border-right-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-bottom {
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-left {
  border-left-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-all {
  border-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-round {
  border-radius: 50%;
}
/*
---
name: .color-box
category: Box/Color
tag:
 - component
 - border
compile: true
---

A box to control the background color. It makes a variant foreach color definion in the file `color-box.var.styl`

```jade
div.color-box.-white(style="width:100px;height:100px")
```

```jade
div.color-box.-action(style="width:100px;height:100px")
```
*/
.color-box.-body {
  background-color: #fff;
}
.color-box.-action {
  background-color: #3031fe;
}
.color-box.-white {
  background-color: #fff;
}
.color-box.-black {
  background-color: #000;
}
/*
---
name: .margin-box
category: Box/Margin
tag:
 - component
 - layout
compile: true
---

A component to add margins

## Applying margins

The easiest use case is to use simple `.margin-box .-all` for a margin on top, right, bottom and left with default size or
`.-top`, `.-right`, `.-bottom` and `.-left` for specified sides.

```jade
div.margin-box.-all
	div.aigis-debug Default margin applied on top, right, bottom and left
```
```jade
div.margin-box.-bottom
	div.aigis-debug Default margin applied on bottom
```


## Variants

The variants for the margins are `.-top-{size}`, `.-right-{size}`, `.-bottom-{size}` and `.left-{size}` together with sizes small, medium or large.
All sizes are defined in the variable theme.components.margin-box. Values are `small`, `medium`, `large` and `auto`

```jade
div.margin-box.-left-small
    div.aigis-debug Small margin applied on left
```
```jade
div.margin-box.-right-small
    div.aigis-debug Small margin applied on right
```
```jade
div.margin-box.-top-small
    div.aigis-debug Small margin applied on top
```
```jade
div.margin-box.-bottom-small
    div.aigis-debug Small margin applied on bottom
```

There are also shortcuts for vertical `.-v-{size}` and horizontal `.-h-{size}` together with sizes small, medium and large

```jade
div.margin-box.-v-medium
    div.aigis-debug Medium margin applied on top and bottom
```
```jade
div.margin-box.-h-medium
    div.aigis-debug Medium margin applied on right and left
```

## Variants for auto margins

```jade
div.margin-box.-auto.-h(style="width: 200px")
    div.aigis-debug Auto margin applied on right and left
```
```jade
div.margin-box.-auto.-v(style="height: 46px")
    div.aigis-debug Auto margin applied on top and bottom
```

*/
.margin-box.-top,
.sidebar-content {
  margin-top: 1.25rem;
}
.margin-box.-right {
  margin-right: 1.25rem;
}
.margin-box.-bottom {
  margin-bottom: 1.25rem;
}
.margin-box.-left {
  margin-left: 1.25rem;
}
.margin-box.-h {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.margin-box.-v {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.margin-box.-all {
  margin: 1.25rem 1.25rem;
}
.margin-box.-top-small {
  margin-top: 0.5rem;
}
.margin-box.-right-small {
  margin-right: 0.5rem;
}
.margin-box.-bottom-small {
  margin-bottom: 0.5rem;
}
.margin-box.-left-small {
  margin-left: 0.5rem;
}
.margin-box.-h-small {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.margin-box.-v-small {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.margin-box.-all-small {
  margin: 0.5rem 0.5rem;
}
.margin-box.-top-medium {
  margin-top: 1.875rem;
}
.margin-box.-right-medium {
  margin-right: 1.875rem;
}
.margin-box.-bottom-medium {
  margin-bottom: 1.875rem;
}
.margin-box.-left-medium {
  margin-left: 1.875rem;
}
.margin-box.-h-medium {
  margin-left: 1.875rem;
  margin-right: 1.875rem;
}
.margin-box.-v-medium {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}
.margin-box.-all-medium {
  margin: 1.875rem 1.875rem;
}
.margin-box.-top-large {
  margin-top: 3.75rem;
}
.margin-box.-right-large {
  margin-right: 3.75rem;
}
.margin-box.-bottom-large {
  margin-bottom: 3.75rem;
}
.margin-box.-left-large {
  margin-left: 3.75rem;
}
.margin-box.-h-large {
  margin-left: 3.75rem;
  margin-right: 3.75rem;
}
.margin-box.-v-large {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}
.margin-box.-all-large {
  margin: 3.75rem 3.75rem;
}
.margin-box.-top-auto {
  margin-top: auto;
}
.margin-box.-right-auto {
  margin-right: auto;
}
.margin-box.-bottom-auto {
  margin-bottom: auto;
}
.margin-box.-left-auto {
  margin-left: auto;
}
.margin-box.-h-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-box.-v-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-box.-all-auto {
  margin: auto auto;
}
.margin-box.-auto.-v {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-box.-auto.-h {
  margin-left: auto;
  margin-right: auto;
}
/*
---
name: .padding-box
category: Box/Padding
tag:
 - component
 - layout
compile: true
---

A simple component to add paddings

## Applying paddings

The easiest use case is to use simple `.padding-box .-all` for a padding on top, right, bottom and left with default size or
`.-top`, `.-right`, `.-bottom` and `.-left` for specified sides

```jade
div.aigis-debug.padding-box.-all Defaut padding applied on top, right, bottom and left
```
```jade
div.aigis-debug.padding-box.-bottom Defaut padding applied on bottom
```

## Variants

The variants for the paddings are `.-top-{size}`, `.-right-{size}`, `.-bottom-{size}` and `.left-{size}` together with sizes small, medium or large.
All sizes are defined in the variable theme.components.padding-box. Values are `small`, `medium`, and `large`

```jade
div.aigis-debug.padding-box.-left-small Small padding applied on left
```
```jade
div.aigis-debug.padding-box.-right-small(style="text-align:right") Small padding applied on right
```
```jade
div.aigis-debug.padding-box.-top-small Small padding applied on top
```
```jade
div.aigis-debug.padding-box.-bottom-small Small padding applied on bottom
```

There are also shortcuts for vertical `.-v-{size}` and horizontal `.-h-{size}` together with sizes

```jade
div.aigis-debug.padding-box.-v-medium Medium padding applied on top and bottom
```
```jade
div.aigis-debug.padding-box.-h-medium(style="width: 300px") Medium padding applied on right and left
```
```jade
div.aigis-debug.padding-box.-h-medium.-top-large(style="width: 400px") Medium padding applied on right and left, large padding applied on top
```
*/
.padding-box.-top {
  padding-top: 0.5rem;
}
.padding-box.-right {
  padding-right: 0.5rem;
}
.padding-box.-bottom {
  padding-bottom: 0.5rem;
}
.padding-box.-left {
  padding-left: 0.5rem;
}
.padding-box.-h {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.padding-box.-v {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.padding-box.-all {
  padding: 0.5rem 0.5rem;
}
.padding-box.-top-small {
  padding-top: 0.125rem;
}
.padding-box.-right-small {
  padding-right: 0.125rem;
}
.padding-box.-bottom-small {
  padding-bottom: 0.125rem;
}
.padding-box.-left-small {
  padding-left: 0.125rem;
}
.padding-box.-h-small {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.padding-box.-v-small {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.padding-box.-all-small {
  padding: 0.125rem 0.125rem;
}
.padding-box.-top-medium {
  padding-top: 1rem;
}
.padding-box.-right-medium {
  padding-right: 1rem;
}
.padding-box.-bottom-medium {
  padding-bottom: 1rem;
}
.padding-box.-left-medium {
  padding-left: 1rem;
}
.padding-box.-h-medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.padding-box.-v-medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.padding-box.-all-medium {
  padding: 1rem 1rem;
}
.padding-box.-top-large {
  padding-top: 2rem;
}
.padding-box.-right-large {
  padding-right: 2rem;
}
.padding-box.-bottom-large {
  padding-bottom: 2rem;
}
.padding-box.-left-large {
  padding-left: 2rem;
}
.padding-box.-h-large {
  padding-left: 2rem;
  padding-right: 2rem;
}
.padding-box.-v-large {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.padding-box.-all-large {
  padding: 2rem 2rem;
}
.padding-box.-gutter {
  padding: 0.9375rem 0.9375rem;
}
/*
---
name: .shadow-box
category: Box/Shadow
tag:
 - component
 - shadow
 - helper
compile: true
---

A box to control box-shadows with different variants

## Default variants
```jade
div.shadow-box Demo content
div.shadow-box.-inset Demo content
div.shadow-box.-zero Use zero shadow in animations
```
*/
.shadow-box,
.bg-circle.-shadow {
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.17);
}
.shadow-box.-inset,
.bg-circle.-shadow.-inset {
  box-shadow: inset 0px 1px 6px 0px rgba(0,0,0,0.17);
}
.shadow-box.-zero,
.bg-circle.-shadow.-zero {
  box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.17);
}
/*
---
name: .transform-box
category: Box/Transform
tag:
 - component
 - tranform
 - helper
compile: true
---

A box to control common transformations

## Rotate
```jade
div.transform-box.-rot90 Some demo content
div.transform-box.-rot180 Some demo content
div.transform-box.-rot270 Some demo content
```

## Flip
```jade
div.transform-box.-flipX Some demo content
div.transform-box.-flipY Some demo content
```
*/
.transform-box.-rot90 {
  transform: rotate(90deg);
}
.transform-box.-rot180 {
  transform: rotate(180deg);
}
.transform-box.-rot270 {
  transform: rotate(270deg);
}
.transform-box.-flipX {
  transform: scaleX(-1);
}
.transform-box.-flipY {
  transform: scaleY(-1);
}
/*
---
name: .button-style
category: Components/Button
tag:
 - component
 - button
compile: true
---

A button component as a base for different button variants defined in its configuration.

```jade
button.button-style.-main Action
```
*/
.button-style {
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.button-style.-main {
  color: #fff;
  background-color: #3031fe;
  padding: 0.125rem 0.5rem;
}
.button-style.-main.-hover:hover {
  color: #fff;
  background-color: #1616bd;
}
.button-style.-hover {
  transition: all 0.1s;
}
.button-style.-hover:hover {
  color: #3031fe;
}
.button-style.-disabled {
  background-color: #b6b6b6;
}
/*
---
name: .drop-down-menu
category: DropDown
tag:
 - component
 - dropdown
compile: true
---

This styles a menu inside a .drop-down.

```jade
div.drop-down-menu
	div.item Menu 1
	div.item Menu 2
	div.item Menu 3
```

Used in combination with the .drop-down itself
```jade
div.drop-down.-open
	div.toggle.aigis-debug Toggle
	div.content.drop-down-menu
		div.item Menu 1
		div.item Menu 2
		div.item Menu 3
```

*/
.drop-down-menu {
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.17);
  border: 1px solid #e0e0e0;
  border-top: none;
}
.drop-down-menu > .item.-no-wrap {
  white-space: nowrap;
}
.drop-down-menu > .item + .item {
  border-top: 1px solid #e0e0e0;
}
/*
---
name: .drop-down
category: DropDown
tag:
 - component
 - dropdown
compile: true
---

This dropdown has the simple mechanism to open and close a dropdown content.

Trigger with mouseover by adding `.-hover` variant

Trigger with js by adding `.-open` variant 

Closed Dropdown
```jade
div.drop-down
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
## Variants

Variants `.-top`, `.-bottom`, `.-left` and `.-right` can be combined

Bottom left
```jade
div.drop-down.-hover.-bottom.-left
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Bottom right
```jade
div.drop-down.-hover.-bottom.-right
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Top right
```jade
div.drop-down.-hover.-top.-right
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Top left
```jade
div.drop-down.-hover.-top.-left
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
## Aside

There is an `.-aside` variant to open content aside

Top left aside
```jade
div.drop-down.-hover.-top.-left.-aside(style="left:200px;width:50%")
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Top right aside
```jade
div.drop-down.-hover.-top.-right.-aside(style="width:50%")
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
## Relative content

There is a variant for opening the content relative instead of absolute

Relative
```jade
div.drop-down.-hover.-relative(style="width:50%")
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

JS Trigger
```jade
div.drop-down.-open.-bottom.-left
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

## Do not wrap content

A variant preventing the content from wrapping


```jade
div.drop-down.-hover.-nowrap
	div.toggle.aigis-debug Toggle
	div.content Dropdown content with some whitespaces
```

*/
.drop-down {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.drop-down.-open > .content {
  display: block;
}
.drop-down.-hover:hover > .content {
  display: block;
}
.drop-down.-top > .content {
  bottom: 100%;
}
.drop-down.-bottom > .content {
  top: 100%;
}
.drop-down.-left > .content {
  left: 0;
}
.drop-down.-top.-left.-aside > .content {
  transform: translate(-100%, 100%);
}
.drop-down.-right > .content {
  right: 0;
}
.drop-down.-top.-right.-aside > .content {
  transform: translate(100%, 100%);
}
.drop-down.-relative > .content {
  position: relative;
}
.drop-down.-nowrap > .content {
  white-space: nowrap;
}
.drop-down > .toggle {
  display: inline-block;
}
.drop-down > .content {
  position: absolute;
  z-index: 1000;
  display: none;
}
.svg-icon {
  fill: currentColor;
  display: block;
  width: 2rem;
  height: 2rem;
}
.svg-icon.-inline {
  display: inline-block;
}
.svg-icon.-button {
  cursor: pointer;
  fill: #202023;
}
.svg-icon.-button:hover {
  fill: #f18e00;
}
.svg-icon.-lernspur-arrow {
  cursor: pointer;
  fill: #fff;
}
.svg-icon.-lernspur-arrow:hover {
  fill: #f18e00;
}
.svg-icon.-size-small {
  width: 1.4375rem;
  height: 1.4375rem;
}
/*
---
name: .abs-layout
category: Layout/Absolute
tag:
 - component
 - layout
compile: true
---

A simple component to position an element absolute. Default is top, left

## Variants

The variants for the vertical positioning are `.-top`, `.-vcenter` and `.-bottom`.
The variants for the horizontal positioning are `.-right`, `.-hcenter` and `.-left`.
Additinally the `-center` variant will center horizontal and vertical.
Variants to stretch an item to its full width/height can be dine with `.item.-stretch`.
In some situations the abs-layout itself needs to stretch so use the stretch variant on the component. `.abs-layout.-stretch` 

```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-center
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-hcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-right
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-right.-vcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-right.-bottom
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-bottom.-hcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-bottom.-left
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-left.-vcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-left.-top
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-stretch
```

## Z-Index

Every item can controll its z index with the `.-z{i}`variants.
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-left.-z1
    div.aigis-debug.item.-top.-left.-z2
```

*/
.abs-layout {
  position: relative;
}
.abs-layout.-stretch {
  width: 100%;
  height: 100%;
}
.abs-layout > .item {
  position: absolute;
}
.abs-layout > .item.-top {
  top: 0;
}
.abs-layout > .item.-right {
  right: 0;
}
.abs-layout > .item.-bottom {
  bottom: 0;
}
.abs-layout > .item.-left {
  left: 0;
}
.abs-layout > .item.-vcenter {
  top: 50%;
  transform: translateY(-50%);
}
.abs-layout > .item.-hcenter {
  left: 50%;
  transform: translateX(-50%);
}
.abs-layout > .item.-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.abs-layout > .item.-stretch {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.abs-layout > .item.-z1 {
  z-index: 1;
}
.abs-layout > .item.-z2 {
  z-index: 2;
}
.abs-layout > .item.-z3 {
  z-index: 3;
}
.abs-layout > .item.-z4 {
  z-index: 4;
}
.abs-layout > .item.-z5 {
  z-index: 5;
}
.abs-layout > .item.-z6 {
  z-index: 6;
}
.abs-layout > .item.-z7 {
  z-index: 7;
}
.abs-layout > .item.-z8 {
  z-index: 8;
}
.abs-layout > .item.-z9 {
  z-index: 9;
}
.abs-layout > .item.-z10 {
  z-index: 10;
}
/*
---
name: .fixed-layout
category: Layout/Fixed
tag:
 - container
---

Fixed container
You can position the container with the different variants `.-top`, `.-bottom`, `.-left`, `.-right`.
To span full width / height use `.-left` & `.-right` / `.-top` & `.-bottom`

Fixed top and full width

```jade
div.fixed-layout.-top.-left.-right.aigis-debug top left fullwidth
    
```

Fixed right and full height

```jade
div.fixed-layout.-top.-bottom.-right.aigis-debug top right fullheight
    
```

*/
.fixed-layout {
  position: fixed;
}
.fixed-layout.-top {
  top: 0;
}
.fixed-layout.-bottom {
  bottom: 0;
}
.fixed-layout.-left {
  left: 0;
}
.fixed-layout.-right {
  right: 0;
}
/*
---
name: .fixed-page-layout
category: Layout/Fixed Page
tag:
 - component
 - page
compile: true
---

A page layout with a fixed header and optional sidebars.
With the `-margin-{top|left|right}`, `-margin-{top|left|right}-small` variants you can control the spacing.
If the content for example needs a spacing towards the header use: `.content.-margin-top`.
If the sidebar show be below the header use: `.sidebar.-left.-margin-top`.


There are variants to resize the header and sidebars to a smaller width/height: `.header.-small`, `.sidebar.-small`.
If you need a spacing on your content or sidebar you need to adjust that manually like this:
```
div.fixed-page-layout
	div.header.-small
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top-small Content
	div.sidebar.-left.-margin-top-small.-small
		div.aigis-debug(style="height:100%")  Left Sidebar
```

## Header with sidebars

```jade
div.fixed-page-layout
	div.header
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top Content
	div.sidebar.-left.-margin-top
		div.aigis-debug(style="height:100%")  Left Sidebar
	div.sidebar.-right
		div.aigis-debug(style="height:100%")  Right Sidebar
```
*/
.fixed-page-layout > .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3.5625rem;
  transition: all 0.2s;
  z-index: 1000;
}
.fixed-page-layout > .header.-small {
  height: ;
}
.fixed-page-layout > .header.-hide {
  transform: translateY(-100%);
}
.fixed-page-layout > .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 20.8125rem;
  transition: all 0.2s;
  z-index: 1005;
}
.fixed-page-layout > .sidebar.-margin-top {
  top: 3.5625rem;
}
.fixed-page-layout > .sidebar.-margin-top-small {
  top: ;
}
.fixed-page-layout > .sidebar.-small {
  width: ;
}
.fixed-page-layout > .sidebar.-left {
  left: 0;
}
.fixed-page-layout > .sidebar.-left.-hide {
  transform: translateX(-100%);
}
.fixed-page-layout > .sidebar.-right {
  right: 0;
}
.fixed-page-layout > .sidebar.-right.-hide {
  transform: translateX(100%);
}
.fixed-page-layout > .content {
  transition: margin 0.2s;
}
.fixed-page-layout > .content.-margin-left {
  margin-left: 20.8125rem;
}
.fixed-page-layout > .content.-margin-left-small {
  margin-left: ;
}
.fixed-page-layout > .content.-margin-right {
  margin-right: 20.8125rem;
}
.fixed-page-layout > .content.-margin-right-small {
  margin-right: ;
}
.fixed-page-layout > .content.-margin-top {
  margin-top: 3.5625rem;
}
.fixed-page-layout > .content.-margin-top-small {
  margin-top: ;
}
/*
---
name: .flex-layout
category: Layout/Flex
tag:
 - component
 - layout
 - flexbox
compile: true
---

A layout system based on flexbox to align and distribute items horizontally and vertically.


# Alignment of items

The `-center` shortcut will center all items horizontal & vertical
```jade
div(style="height: 100px").flex-layout.-center
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```

`-baseline` will align items based on their baseline. Works good if all the items contain text. 
```jade
div.flex-layout.-baseline
	div.aigis-debug Item
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
```

`-items-top` & `-items-bottom` will align the items either on top or bottom
```jade
div.flex-layout.-items-top
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```
```jade
div.flex-layout.-items-bottom
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```

`-items-center` will center the items vertically
```jade
div.flex-layout.-items-center
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```

# Alignment of individual items

```jade
div(style="height: 100px").flex-layout
	div.aigis-debug.item.-top top
	div.aigis-debug.item.-center center
	div.aigis-debug.item.-bottom bottom
	div.aigis-debug.item.-stretch stretch
```

# Row mode

If you like a container that spans the full width with item that resize themself you can use the `-row` variant.

```jade
div.flex-layout.-row
	div.aigis-debug.item Item 1
	div.aigis-debug.item Item 2
	div.aigis-debug.item Item 3
```

If you like to fix an item based on its content width use the `-content` variant.
This is a shortcut for `-no-grow -no-shrink`. 
```jade
div.flex-layout.-row
	div.aigis-debug.item.-content
		img(src="http://via.placeholder.com/350x80")
	div.aigis-debug.item Item 2
	div.aigis-debug.item Item 3
```

You can control the sizing of the items with `-grow`, `-shrink`, `-no-grow`, `-no-shrink`.
```jade
div.flex-layout.-row
	div.aigis-debug.item.-no-shrink
		img(src="http://via.placeholder.com/350x80")
	div.aigis-debug.item.-no-grow Item 2
	div.aigis-debug.item Item 3
```


# Distribution of items

Use `-[v/h]-space-around` & `-[v/h]-space-between` to distribute the items in the flex container.

```jade
div.flex-layout.-h-space-around
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```
```jade
div.flex-layout.-h-space-between
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```
```jade
div(style="height: 200px").flex-layout.-v-space-around.-wrap
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-v-space-between.-wrap
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```

# Alignment of multiline items with wrap

If you have alot on items that wrap you can align the multilines with the following variants:
```jade
div.flex-layout.-wrap.-h-center
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div.flex-layout.-wrap.-left
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div.flex-layout.-wrap.-right
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-v-center
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-top
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-bottom
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```




*/
.flex-layout,
.bg-circle,
.pagination-steps {
  display: flex;
}
.flex-layout.-inline,
.bg-circle.-inline,
.pagination-steps.-inline {
  display: flex-inline;
}
.flex-layout.-wrap,
.bg-circle.-wrap,
.pagination-steps.-wrap {
  flex-wrap: wrap;
}
.flex-layout.-row,
.bg-circle.-row,
.pagination-steps.-row,
.pagination-steps {
  flex-wrap: nowrap;
}
.flex-layout.-row > .item,
.bg-circle.-row > .item,
.pagination-steps.-row > .item,
.pagination-steps > .item {
  flex: 1 1 auto;
}
.flex-layout.-column,
.bg-circle.-column,
.pagination-steps.-column {
  flex-direction: column;
}
.flex-layout.-center,
.bg-circle.-center,
.pagination-steps.-center,
.bg-circle {
  justify-content: center;
  align-items: center;
  align-content: center;
}
.flex-layout.-baseline,
.bg-circle.-baseline,
.pagination-steps.-baseline {
  align-items: baseline;
}
.flex-layout.-left,
.bg-circle.-left,
.pagination-steps.-left {
  justify-content: flex-start;
}
.flex-layout.-right,
.bg-circle.-right,
.pagination-steps.-right {
  justify-content: flex-end;
}
.flex-layout.-top,
.bg-circle.-top,
.pagination-steps.-top {
  align-content: flex-start;
}
.flex-layout.-bottom,
.bg-circle.-bottom,
.pagination-steps.-bottom {
  align-content: flex-end;
}
.flex-layout.-v-center,
.bg-circle.-v-center,
.pagination-steps.-v-center {
  align-content: center;
}
.flex-layout.-v-space-between,
.bg-circle.-v-space-between,
.pagination-steps.-v-space-between {
  align-content: space-between;
}
.flex-layout.-v-space-around,
.bg-circle.-v-space-around,
.pagination-steps.-v-space-around {
  align-content: space-around;
}
.flex-layout.-h-center,
.bg-circle.-h-center,
.pagination-steps.-h-center {
  justify-content: center;
}
.flex-layout.-h-space-between,
.bg-circle.-h-space-between,
.pagination-steps.-h-space-between {
  justify-content: space-between;
}
.flex-layout.-h-space-around,
.bg-circle.-h-space-around,
.pagination-steps.-h-space-around {
  justify-content: space-around;
}
.flex-layout.-items-top,
.bg-circle.-items-top,
.pagination-steps.-items-top {
  align-items: flex-start;
}
.flex-layout.-items-bottom,
.bg-circle.-items-bottom,
.pagination-steps.-items-bottom {
  align-items: flex-end;
}
.flex-layout.-items-center,
.bg-circle.-items-center,
.pagination-steps.-items-center {
  align-items: center;
}
.flex-layout.-items-stretch,
.bg-circle.-items-stretch,
.pagination-steps.-items-stretch {
  align-items: flex-stretch;
}
.flex-layout > .item.-stretch,
.bg-circle > .item.-stretch,
.pagination-steps > .item.-stretch {
  align-self: stretch;
}
.flex-layout > .item.-top,
.bg-circle > .item.-top,
.pagination-steps > .item.-top {
  align-self: flex-start;
}
.flex-layout > .item.-center,
.bg-circle > .item.-center,
.pagination-steps > .item.-center {
  align-self: center;
}
.flex-layout > .item.-bottom,
.bg-circle > .item.-bottom,
.pagination-steps > .item.-bottom {
  align-self: flex-end;
}
.flex-layout > .item.-content,
.bg-circle > .item.-content,
.pagination-steps > .item.-content {
  flex: none;
}
.flex-layout > .item.-grow,
.bg-circle > .item.-grow,
.pagination-steps > .item.-grow {
  flex-grow: 1;
}
.flex-layout > .item.-shrink,
.bg-circle > .item.-shrink,
.pagination-steps > .item.-shrink {
  flex-shrink: 1;
}
.flex-layout > .item.-no-grow,
.bg-circle > .item.-no-grow,
.pagination-steps > .item.-no-grow {
  flex-grow: 0;
}
.flex-layout > .item.-no-shrink,
.bg-circle > .item.-no-shrink,
.pagination-steps > .item.-no-shrink {
  flex-shrink: 0;
}
/*
---
name: .grid-layout
category: Layout/Grid
tag:
 - component
 - layout
 - grid
 - responsive
 - flexbox
compile: true
---

A grid layout system based on flexbox. It provides sizing variants to size on different percentages.


## Percentage based sizing

The easiest use case is to use simple `.item -w1`, `.item -w1-2`, `.item -w1-3`, `.item -w1-4` for a percentage based side-by-side rendering.

```jade
div.grid-layout
	div.item.-w1
		div.aigis-debug item -w1
	div.item.-w1-2 
		div.aigis-debug item -w1-2
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-3
		div.aigis-debug item -w1-3
	div.item.-w2-3
		div.aigis-debug item -w2-3
	div.item.-w1-4
		div.aigis-debug item -w1-4
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-4
		div.aigis-debug item -w1-4
```

## Layouts on different devices

The breakpoints for different screen sizes are specified in the global settings `theme.settings.breakpoints` and can be overwritten in `theme.components.grid-layout.breakpoints`.
The default settings define breakpoints with labels: `.-small-X-X`, `.-medium-X-X`, `.-large-X-X`.

```jade
div.grid-layout
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1 -medium-1-2 -large-1-4
```


## Gutter

If you need a spacing between the columns add the `-gutter` variant.
The size of the gutter is specified in the `grid-gutter`variable.

```jade
div.grid-layout.-gutter
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-2
		div.aigis-debug col -w1-2
```


## Reverse order

To reverse the order in which the columns are displayed, you can add the modifier class `.-reverse` to your table-grid.
The columns will be in DOM order when viewport switches to small layout.

```jade
div.grid-layout.-reverse
	div.item.-w1-4
		div.aigis-debug First column in DOM
	div.item.-w1-4
		div.aigis-debug Second column in DOM
	div.item.-w1-4
		div.aigis-debug Third column in DOM
	div.item.-w1-4
		div.aigis-debug Fourth column in DOM
```

## Vertical centering

You can vertical center with the help of the `flex-layout` component.

```jade
div.grid-layout.flex-layout
	div.item.-w1-4
		div.aigis-debug A column with a lot of content that should wrap on fixed size.
	div.item.-bottom
		div.aigis-debug bottom
	div.item.-center
		div.aigis-debug center
	div.item.-stretch
		div.aigis-debug(style="height:100%") stretch
```

*/
.grid-layout {
  display: flex;
  flex-wrap: wrap;
}
.grid-layout.-gutter {
  margin: -0.625rem -0.9375rem;
}
.grid-layout.-gutter > .item {
  padding: 0.625rem 0.9375rem;
}
.grid-layout.-reverse {
  flex-direction: row-reverse;
}
.grid-layout > .item.-w1 {
  flex: 0 0 100%;
  max-width: 100%;
}
.grid-layout > .item.-w1-2 {
  flex: 0 0 50%;
  max-width: 50%;
}
.grid-layout > .item.-w1-3 {
  flex: 0 0 33.333333333333336%;
  max-width: 33.333333333333336%;
}
.grid-layout > .item.-w2-3 {
  flex: 0 0 66.66666666666667%;
  max-width: 66.66666666666667%;
}
.grid-layout > .item.-w1-4 {
  flex: 0 0 25%;
  max-width: 25%;
}
.grid-layout > .item.-w3-4 {
  flex: 0 0 75%;
  max-width: 75%;
}
@media all and (min-width: 48rem) {
  .grid-layout > .item.-small-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-small-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-small-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-small-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-small-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-small-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media all and (min-width: 62rem) {
  .grid-layout > .item.-medium-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-medium-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-medium-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-medium-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-medium-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-medium-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
@media all and (min-width: 75rem) {
  .grid-layout > .item.-large-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-large-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-large-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-large-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-large-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-large-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
/*
---
name: .max-width-layout
category: Layout/Page
tag:
 - component
 - layout
 - responsive
compile: true
---

This component will limit its content to a maximum width. Forthermore it can center its content.
Usefull for page content that should not span the full width.


## Percentage based sizing

*/
@media all and (min-width: 48rem) {
  .max-width-layout {
    max-width: 41.75rem;
  }
}
@media all and (min-width: 62rem) {
  .max-width-layout {
    max-width: 55.75rem;
  }
}
@media all and (min-width: 75rem) {
  .max-width-layout {
    max-width: 68.75rem;
  }
}
.max-width-layout.-center {
  margin-left: auto;
  margin-right: auto;
}
/*
---
name: .slide-overlay
category: Overlay/Slide
tag:
 - rollover
 - slide
 - overlay
---

Rollover component - content rollover rolls in - default from bottom

Trigger with mouserover by adding `.-hover` variant

Trigger with js by adding `.-show` variant

```jade
div.slide-overlay.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -left
```jade
div.slide-overlay.-left.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -right
```jade
div.slide-overlay.-right.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -top
```jade
div.slide-overlay.-top.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -top with js trigger
```jade
div.slide-overlay.-top.-show
    div Some main content
    div.rollover Rollover content
```
*/
.slide-overlay {
  position: relative;
  overflow: hidden;
}
.slide-overlay > .rollover {
  position: absolute;
  bottom: 0;
  transform: translate(0, 100%);
  transition: transform 0.5s ease;
}
.slide-overlay.-left > .rollover {
  transform: translate(-100%, 0);
  left: 0;
}
.slide-overlay.-right > .rollover {
  transform: translate(100%, 0);
  right: 0;
}
.slide-overlay.-top > .rollover {
  transform: translate(0, -100%);
  top: 0;
}
.slide-overlay.-show > .rollover {
  transform: translate(0, 0);
}
.slide-overlay.-hover:hover > .rollover {
  transform: translate(0, 0);
}
/*
---
name: .control-responsive
category: Responsive/Control
tag:
 - responsive
 - display
compile: true
---

Controls the display of an element depending on the current breakpoint

## Hide

`.-hide-medium` hides an element as long as the screen is wider than the breakpoint `medium`.

```jade
div.control-responsive.-hide-medium Only shown when the screen is wider than the breakpoint medium
```

## Show

`.-show-medium` show an element as long as the screen is narrower than the breakpoint `medium`.

```jade
div.control-responsive.-show-medium Only shown when the screen is narrower than the breakpoint `medium`
```

*/
@media all and (max-width: 48rem) {
  .control-responsive.-hide-small {
    display: none;
  }
}
@media all and (min-width: 48rem) {
  .control-responsive.-show-small {
    display: none;
  }
}
@media all and (max-width: 62rem) {
  .control-responsive.-hide-medium {
    display: none;
  }
}
@media all and (min-width: 62rem) {
  .control-responsive.-show-medium {
    display: none;
  }
}
@media all and (max-width: 75rem) {
  .control-responsive.-hide-large {
    display: none;
  }
}
@media all and (min-width: 75rem) {
  .control-responsive.-show-large {
    display: none;
  }
}
@media all and (max-width: 48rem) {
  .hide-responsive.-small {
    display: none;
  }
}
@media all and (max-width: 62rem) {
  .hide-responsive.-medium {
    display: none;
  }
}
@media all and (max-width: 75rem) {
  .hide-responsive.-large {
    display: none;
  }
}
/*
---
name: .image-responsive
category: Responsive/Image
tag:
 - responsive
compile: true
---

This class makes the image scaling to its max width.

```jade
img.image-responsive(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```

## Variants

`.-full` forces the picture to span to 100% of the parent

```jade
img.image-responsive.-full(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```
*/
.image-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}
.image-responsive.-full {
  width: 100%;
}
.image-responsive.-inset {
  max-height: 100%;
}
/*
---
name: .media-responsive
category: Responsive/Media
tag:
 - responsive
compile: true
---

`.media-responsive` - This makes a the `.media-responsive` div a container for any media element (iframe, video, ... ) that responsive scales with a fix ratio.
The media element ( child ) needs the class `.media` to fully fill out the parents size.

The `.media-responsive` component has to predefined modifiers: `.-media-4-3`, `.-media-16-9` that define a fix ratio.

```jade
div.media-responsive.-media-16-9
	iframe.media(src="https://www.youtube.com/embed/TCd6PfxOy0Y")
```

```jade
div.media-responsive.-media-4-3
	iframe.media(src="https://www.youtube.com/embed/TCd6PfxOy0Y")
```

*/
.media-responsive {
  width: 100%;
  position: relative;
  padding-bottom: 50%;
}
.media-responsive.-media-1-1 {
  padding-bottom: 50%;
}
.media-responsive.-media-4-3 {
  padding-bottom: 75%;
}
.media-responsive.-media-16-9 {
  padding-bottom: 56.25%;
}
.media-responsive > .media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*
---
name: .overflow-text
category: Text
tag:
 - component
 - text
compile: true
---

This component cuts the text on a given height and adds a fadeout effect.
This is usefull for text containers that have more content than space.


```jade
div.overflow-text(style="width: 200px;height: 70px") This is a very long text that is rwaped and cut at the end.
```
*/
.overflow-text {
  overflow: hidden;
  position: relative;
}
.overflow-text:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 2.1875rem;
  background: linear-gradient(to bottom, rgba(255,255,255,0), #fff 70%);
}
/*
---
name: .text-style
category: Text
tag:
 - component
 - text
compile: true
---

A text styling component for different text styles

## Default heading

```jade
div.text-style Default heading
```

## H1
```jade
div.text-style.-h1 The h1 header

```

## H2
```jade
div.text-style.-h2 The h2 header
```

## H3
```jade
div.text-style.-h3 The h3 header
```

## Bold
```jade
div.text-style.-bold Bold text
```

## Align center
```jade
div.text-style.-center Centered text
```

## Align right
```jade
div.text-style.-right Right aligned text
```

*/
.text-style.-h1 {
  font-family: 'Roboto' , serif;
  font-size: 3rem;
  line-height: 3.625rem;
  font-weight: 700;
}
.text-style.-h2 {
  font-size: 3rem;
  line-height: 3rem;
}
.text-style.-h3 {
  font-family: 'Roboto' , serif;
  font-size: 1.625rem;
  line-height: 2.25rem;
  font-weight: 400;
}
.text-style.-center {
  text-align: center;
}
.text-style.-right {
  text-align: right;
}
.text-style.-bold {
  font-weight: 700;
}
.text-style.-medium,
.chapter-navigation.-open > .bar > .title {
  font-weight: 500;
}
.text-style.-inverted {
  color: #fff;
}
.text-style.-indent-1 {
  padding-left: 1.875rem;
}
.text-style.-indent-2 {
  padding-left: 3.75rem;
}
.text-style.-indent-3 {
  padding-left: 5.625rem;
}
.text-style.-indent-4 {
  padding-left: 7.5rem;
}
.text-style.-indent-5 {
  padding-left: 9.375rem;
}
.text-style.-indent-6 {
  padding-left: 11.25rem;
}
.text-style.-h4 {
  font-family: 'Roboto' , serif;
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 500;
}
.text-style.-lead {
  font-family: 'Roboto' , serif;
  font-size: 1.875rem;
  line-height: 2.625rem;
  font-weight: 400;
  color: #6a6a6a;
}
.text-style.-navi,
.chapter-navigation > .bar > .title {
  font-family: Roboto , sans-serif;
  font-size: 1rem;
  letter-spacing: 0.2px;
}
.text-style.-p {
  font-family: Georgia , serif;
  font-size: 1.3125rem;
  line-height: 2.125rem;
  font-weight: 400;
  color: #202023;
}
.text-style.-p ul {
  padding-left: 30px;
}
.text-style.-error {
  color: #f18e00;
}
.text-style.-small {
  font-size: 0.65em;
}
.text-style.-branding {
  color: #616365;
  font-size: 0.875rem;
}
.text-style.-paragraph {
  font-size: 1.125rem;
  line-height: 1.6875rem;
}
.text-style.-tooltip {
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
.text-style.-tooltip-title {
  font-size: 0.8125rem;
  font-weight: bold;
  line-height: 1.5rem;
}
.collapse-ui > .content.-transition {
  transition: height 0.3s ease;
  overflow: hidden;
  height: 0;
}
.collapse-ui.-close > .content {
  display: none;
}
.collapse-ui.-close .collapse-icon {
  transform: rotate(90deg);
}
.collapse-ui .collapse-icon {
  transition: all 0.3s ease;
}
.chapter-layout > .element-layout + .element-layout {
  margin-top: 0.5rem;
}
.chapter-layout > .element-layout + [class*=' text-style -h'] {
  margin-top: 1.875rem;
}
.chapter-layout > .element-layout + .element-layout.-extended {
  margin-top: 1.875rem;
}
.chapter-navigation {
  transition: all 0.2s;
}
.chapter-navigation > .bar {
  height: 3.125rem;
  padding-left: 1.375rem;
}
.chapter-navigation > .bar > .toggle {
  transition: transform 0.4s;
  padding: 0.625rem;
}
.chapter-navigation > .bar > .title {
  padding-left: 0.3125rem;
}
.chapter-navigation > .bar:hover {
  color: ;
  cursor: pointer;
}
.chapter-navigation > .children {
  display: none;
  color: #6a6a6a;
}
.chapter-navigation > .border {
  border-left: 0 solid #3031fe;
  height: 100%;
  transition: all 0.2s;
}
.chapter-navigation.-open {
  background-color: #f5f5f6;
}
.chapter-navigation.-open > .bar {
  color: #3031fe;
  border: none;
}
.chapter-navigation.-open > .bar > .toggle {
  transform: rotate(90deg);
}
.chapter-navigation.-open > .children {
  display: block;
}
.chapter-navigation.-open > .border {
  border-width: 5px;
}
.chapter-navigation .chapter-element.-current > .title {
  background-color: #e8e8e9;
}
.chapter-navigation .chapter-element > .title {
  padding: 0.5rem 1rem 0.5rem 3.5625rem;
  min-height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid #fff;
}
.chapter-navigation .chapter-element > .title:hover {
  color: #3031fe;
}
.element-layout {
  margin-right: auto;
  margin-left: auto;
}
.element-layout.-read {
  max-width: 48.75rem;
}
.element-layout.-extended {
  max-width: 58.75rem;
}
.element-layout.-full {
  max-width: none;
  margin-right: 0;
  margin-left: 0;
  padding: 0;
}
.element-layout.-padded {
  padding: 0 1rem;
}
@media screen and (min-width: 50.75rem) {
  .element-layout.-left > .float {
    float: left;
    padding-right: 1rem;
    padding-bottom: 1rem;
    max-width: 24.375rem;
    margin-left: -pad;
  }
  .element-layout.-right > .float {
    float: right;
    padding-left: 1rem;
    padding-bottom: 1rem;
    max-width: 24.375rem;
    margin-right: -pad;
  }
}
.chapter-element > .cover {
  margin-bottom: 5.625rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 21.875rem;
}
.section-element > .icon {
  left: 1rem;
}
.main-menu {
  padding: 3px 0;
  display: inline-block;
}
.main-menu.-close > .top {
  transform: translateY(8px) rotateZ(45deg);
}
.main-menu.-close > .bot {
  transform: translateY(-8px) rotateZ(-45deg);
}
.main-menu.-close > .mid {
  opacity: 0;
  transform: translateX(-10px);
}
.main-menu > .line {
  height: 3px;
  width: 24px;
  background-color: currentColor;
  transition: all 0.3s;
}
.main-menu > .line + .line {
  margin-top: 5px;
}
.action-button {
  height: 2.5rem;
  border-radius: 1.25rem;
  border: none;
  margin: 0.625rem 0;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #f18e00;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  font-size: 1rem;
  cursor: pointer;
}
.action-button:hover {
  background-color: #616365;
  color: #fff;
}
.action-button.selected {
  background-color: #f18e00;
  color: #fff;
}
@media only screen and (max-width: small-break-point) {
  .action-button {
    margin: 0.3125rem 0;
  }
}
.badge-counter {
  display: inline-block;
  margin-left: 0.625rem;
  background-color: #f18e00;
  text-align: center;
  color: #fff;
  border-radius: 0.5625rem;
  width: 1.125rem;
  height: 1.125rem;
  line-height: 1.125rem;
  font-size: 0.625rem;
  font-weight: normal;
  transform: translateY(-3.5px);
}
.button-style {
  height: 2.5rem;
  border-radius: 1.25rem;
  border: none;
  margin: 0.625rem 0;
  text-align: center;
  text-transform: uppercase;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  font-size: 1rem;
  cursor: pointer;
}
.button-style:hover {
  background-color: #616365;
  color: #fff;
}
.button-style.selected {
  background-color: #f18e00;
  color: #fff;
}
.button-style.-main {
  background-color: #f18e00;
  color: #fff;
}
.button-style.-main:hover {
  background-color: #616365;
  color: #fff;
}
.button-style.-main.selected {
  background-color: #f18e00;
  color: #fff;
}
.button-style.-disabled {
  background-color: #ccc;
  color: #616365;
}
.button-style.-disabled:hover,
.button-style.-disabled.selected {
  background-color: #ccc;
  color: #616365;
}
@media only screen and (max-width: small-break-point) {
  .button-style {
    margin: 0.3125rem 0;
  }
}
.caption-block,
.caption-block p {
  position: relative;
  top: 0.3125rem;
  font-size: 0.9375rem;
  color: #717271;
  font-weight: normal;
  font-style: normal;
}
.caption-block:before {
  content: '';
  position: absolute;
  border-top: 1px solid #d3d3d3;
  width: 3.625rem;
}
@media only screen and (max-width: large-break-point) {
  .caption-block,
  .caption-block p {
    font-size: 0.75rem;
  }
}
@media screen {
  .chapter-timestamp {
    display: none;
  }
}
.book-chapter > .disclaimer {
  display: none;
}
@media print {
  .book-chapter.-confidential > .disclaimer {
    display: block;
  }
  .book-chapter.-confidential > .elements {
    display: none;
  }
  .book-chapter.-confidential > .next-chapter {
    display: none;
  }
}
.chapter-element {
  position: relative;
}
.chapter-element .fresh {
  position: absolute;
  left: -60px;
  transform: rotate(-27deg);
  color: #f18e00;
  top: 5px;
  text-transform: lowercase;
  line-height: 23px;
  font-size: 13px;
  letter-spacing: 1px;
}
.chapter-element .fresh::first-letter {
  border-bottom: 1px solid #f18e00;
}
.chapter-element > p {
  padding: 0.3125rem 0;
}
.chapter {
  max-width: 43.5625rem;
  margin: auto;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}
@media only screen and (max-width: small-break-point) {
  .chapter {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
.cover-dimmer {
  background-color: rgba(0,0,0,0.2);
}
.cover-image > .image {
  width: 100%;
}
.cover-title {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
}
.cover-title > .title {
  font-family: 'HelveticaRoundedLTStd-Bd';
  padding: 1.25rem 3.125rem;
  max-width: 48.75rem;
  color: #fff;
  font-size: 4rem;
  line-height: 4.375rem;
  letter-spacing: 0.0625rem;
}
@media only screen and (max-width: 48rem) {
  .cover-title > .title {
    font-size: 1.75rem;
    line-height: 2rem;
    padding: 0.3125rem 0.625rem;
  }
}
.feedback-form {
  position: relative;
  top: 100px;
  z-index: 999999;
  background-color: #fff;
  width: 600px;
  margin: auto;
  box-shadow: 0px 1px 7px #808080;
  padding: 20px;
  border-radius: 10px;
}
.feedback-form > .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  cursor: pointer;
}
@media only screen and (max-width: small-break-point) {
  .feedback-form {
    width: 100%;
  }
}
.fl-login {
  padding-top: 3.75rem;
}
.foldable-element {
  padding-bottom: 1.25rem;
}
.foldable-element >.expand {
  width: 75px;
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}
.foldable-element >.expand:hover > svg {
  fill: #f19002;
}
.foldable-element >.expand:hover > .arrow {
  background-image: url("../images/icon-arrow-down-hover.svg");
}
.foldable-element >.expand:hover.open > .arrow {
  background-image: url("../images/icon-arrow-up-hover.svg");
}
.foldable-element >.expand > svg {
  fill: rgba(241,144,2,0.8);
}
.foldable-element >.expand.open svg {
  animation-name: svgcolor;
  animation-duration: 1s;
}
.foldable-element >.expand.open > .arrow {
  background-image: url("../images/icon-arrow-up.svg");
}
.foldable-element >.expand.fl-content > svg {
  fill: rgba(134,188,37,0.8);
}
.foldable-element >.expand.fl-content:hover > svg {
  fill: #86bc25;
}
.foldable-element >.expand.fl-content.open > svg {
  animation-name: background-fl;
  animation-duration: 1s;
}
.foldable-element >.expand > .arrow {
  background-image: url("../images/icon-arrow-down.svg");
  margin-left: 0.625rem;
  width: 20px;
  height: 12px;
}
.foldable-element >.element-container {
  position: relative;
  top: 20px;
}
.foldable-element >.element-container >.elements {
  border-left: 5px solid #f19002;
  animation-name: border;
  animation-duration: 1s;
  margin: -4px -30px 30px 0px;
}
.foldable-element >.element-container >.elements > * {
  margin-left: 30px;
}
@media (max-width: 768px) {
  .foldable-element >.element-container >.elements {
    border-left: none;
    padding: 0;
    margin: 0;
  }
  .foldable-element >.element-container >.elements > * {
    margin-left: 0px;
  }
}
.foldable-element >.element-container >.elements >.element:first-child {
  padding-top: 0;
}
.foldable-element >.element-container >.elements >.element >*:first-child {
  padding-top: 0;
}
.foldable-element >.element-container.fl-content-container > .elements {
  border-left: 5px solid #86bc25;
  animation-name: border-fl;
}
@media (max-width: 768px) {
  .foldable-element >.element-container.fl-content-container > .elements {
    border: none;
  }
}
.foldable-element >.element-container img.responsive {
  width: 100%;
}
.foldable-element > .element-container > .elements > .chapter-element {
  padding-bottom: 0;
  padding-top: 0;
}
.foldable-element > .element-container > .elements > .chapter-element p {
  padding-bottom: 0;
}
.explanation {
  padding-top: 0;
}
.explanation >.expand {
  transform: translateX(0px);
  margin-right: 12px;
}
@-moz-keyframes border {
  from {
    border-color: rgba(241,144,2,0.8);
  }
  to {
    border-color: #f19002;
  }
}
@-webkit-keyframes border {
  from {
    border-color: rgba(241,144,2,0.8);
  }
  to {
    border-color: #f19002;
  }
}
@-o-keyframes border {
  from {
    border-color: rgba(241,144,2,0.8);
  }
  to {
    border-color: #f19002;
  }
}
@keyframes border {
  from {
    border-color: rgba(241,144,2,0.8);
  }
  to {
    border-color: #f19002;
  }
}
@-moz-keyframes svgcolor {
  from {
    fill: rgba(241,144,2,0.8);
  }
  to {
    fill: #f19002;
  }
}
@-webkit-keyframes svgcolor {
  from {
    fill: rgba(241,144,2,0.8);
  }
  to {
    fill: #f19002;
  }
}
@-o-keyframes svgcolor {
  from {
    fill: rgba(241,144,2,0.8);
  }
  to {
    fill: #f19002;
  }
}
@keyframes svgcolor {
  from {
    fill: rgba(241,144,2,0.8);
  }
  to {
    fill: #f19002;
  }
}
@-moz-keyframes border-fl {
  from {
    border-color: rgba(134,188,37,0.8);
  }
  to {
    border-color: #86bc25;
  }
}
@-webkit-keyframes border-fl {
  from {
    border-color: rgba(134,188,37,0.8);
  }
  to {
    border-color: #86bc25;
  }
}
@-o-keyframes border-fl {
  from {
    border-color: rgba(134,188,37,0.8);
  }
  to {
    border-color: #86bc25;
  }
}
@keyframes border-fl {
  from {
    border-color: rgba(134,188,37,0.8);
  }
  to {
    border-color: #86bc25;
  }
}
@-moz-keyframes background-fl {
  from {
    fill: #86bc25;
  }
  to {
    fill: #86bc25;
  }
}
@-webkit-keyframes background-fl {
  from {
    fill: #86bc25;
  }
  to {
    fill: #86bc25;
  }
}
@-o-keyframes background-fl {
  from {
    fill: #86bc25;
  }
  to {
    fill: #86bc25;
  }
}
@keyframes background-fl {
  from {
    fill: #86bc25;
  }
  to {
    fill: #86bc25;
  }
}
.footer-logo {
  max-height: 50px;
}
.form-element label {
  font-size: 0.75rem;
}
.form-element input,
.form-element textarea {
  border: 1px solid #000;
  display: block;
  width: 100%;
  padding: 0.25rem;
}
.form-element .invalid-message {
  display: none;
  color: #f18e00;
}
.form-element.-invalid .valid-message {
  display: none;
}
.form-element.-invalid .invalid-message {
  display: block;
}
.glossary-item {
  border-top: 1px solid;
}
.glossary-item > .title {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
  background-color: ;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.glossary-item > .title >.arrow {
  background: url("../images/icon-arrow-up.svg");
  background-repeat: no-repeat;
  height: 0.75rem;
  width: 1.25rem;
}
.glossary-item > .title.-closed > .arrow {
  background: url("../images/icon-arrow-down.svg");
}
.glossary-item > .description {
  padding-bottom: 1.5625rem;
  font-size: 1.375rem;
  background-color: ;
}
.glossary-letters {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 1.6875rem;
  background-color: #f18e00;
}
.glossary-letters a {
  color: #fff;
  padding-right: 0.8125rem;
}
.glossary-list {
  max-width: 53.125rem;
  top: 3.75rem;
  position: relative;
  min-height: calc(100vh - 71px - 60px);
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-top: 3.75rem;
}
.glossary-list > .letter {
  padding-top: 6.875rem;
  padding-bottom: 3.125rem;
  background-color: ;
  font-size: 2.25rem;
}
.glossary-list h1 {
  padding-bottom: 2.5rem;
}
@media only screen and (max-width: small-break-point) {
  .glossary-list {
    top: 11.25rem;
  }
}
.glossary-switch ol li a {
  text-decoration: none;
  color: #000;
}
.glossary-switch ol li.selected a {
  color: #fff;
}
.glossary-term-tooltip-element {
  background: #fff8f0;
  border: solid 1px #ccc;
  border-radius: 3px;
  box-shadow: 1px 1px 5px #ccc;
  padding: 0.3125rem 0.78125rem;
  max-width: 30rem;
  transform: translate(-50%, -100%);
}
.glossary-term {
  text-decoration: underline;
  text-decoration: dotted underline;
  cursor: pointer;
  color: #f18e00;
  text-underline-position: under;
}
.book-title {
  display: inline-block;
  line-height: 3.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fixed-header {
  position: fixed;
  height: 3.75rem;
  width: 100%;
  background-color: rgba(255,255,255,0.9);
  z-index: 10;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
  top: 0;
}
.fixed-header .search-box {
  height: 39px;
  width: 39px;
}
.glossary-link {
  background-image: url("../images/icon-glossary.svg");
  width: 0.9375rem;
  height: 1.875rem;
  background-repeat: no-repeat;
  position: absolute;
  right: 8.75rem;
  top: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #616365;
}
.glossary-link:hover {
  background-image: url("../images/icon-glossary-hover.svg");
}
.print-link {
  background-image: url("../images/icon-print.svg");
  width: 1.875rem;
  height: 1.875rem;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  top: 0.5625rem;
  right: 0.625rem;
}
.feedback-link {
  background-image: url("../images/Button_Feedback.svg");
  width: 1.875rem;
  height: 1.875rem;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  top: 0.5625rem;
  right: 0.625rem;
}
.login-link {
  background-image: url("../images/icon-lock.svg");
  width: 1.4375rem;
  height: 1.875rem;
  background-repeat: no-repeat;
  position: absolute;
  right: 1.875rem;
  top: 1.125rem;
}
.login-link:hover {
  background-image: url("../images/icon-lock-hover.svg");
}
@media only screen and (max-width: small-break-point) {
  .glossary-link {
    right: 6.875rem;
    padding-left: 0;
    width: 1.25rem;
  }
  .login-link {
    right: 1.25rem;
  }
}
.main-mobileheader {
  position: absolute;
  top: 60px;
  border-top: 1px solid;
}
.main-mobileheader .hamburger-mobile {
  position: absolute;
  top: 1.0625rem;
  left: 0.625rem;
  cursor: pointer;
}
.mobile-title {
  height: 3.75rem;
  width: 100vw;
  padding: 0.625rem;
  font-size: 0.9375rem;
  border-bottom: 1px solid #d3d3d3;
  text-align: center;
}
.mobile-title .logo {
  text-align: center;
}
.mobile-title .logo > a {
  font-size: 1.3125rem;
  line-height: 2.5625rem;
  color: #616365;
}
.mobile-title .logo > a:hover {
  color: #f18e00;
}
.abs {
  position: absolute;
}
.hidden {
  display: none;
  visibility: hidden;
}
.image-element {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.image-element > .image {
  margin: 0 auto;
}
.image-element .caption,
.image-element .caption p {
  font-size: 0.9375rem;
  text-align: center;
  font-weight: bold;
  color: #5a5a5a;
  margin-top: 5px;
}
.image-element.-screen > .bg {
  background-color: rgba(204,204,204,0.5);
}
.image-element.-screen > .bg > .image {
  margin: 0 auto;
}
.gallery-image-container > .item {
  position: absolute;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.gallery-image-container > .item > .prev,
.gallery-image-container > .item .next {
  display: flex;
  width: 50%;
  height: 100%;
}
.gallery-image-container > .item > .prev {
  margin-left: 0.625rem;
  justify-content: flex-start;
}
.gallery-image-container > .item > .next {
  margin-right: 0.625rem;
  justify-content: flex-end;
}
.image-gallery {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.image-gallery > .gallery-image-container {
  width: 100%;
  display: flex;
}
.image-gallery > .gallery-image-container .gallery-image .responsive {
  max-width: 100%;
  min-width: 100%;
}
.image-gallery > .gallery-image-container .gallery-image .caption,
.image-gallery > .gallery-image-container .gallery-image .caption p {
  font-size: 0.9375rem;
  line-height: 1.3125rem;
  padding-bottom: 0.625rem;
  position: relative;
  top: 4px;
}
.caption--gallery {
  width: 100%;
  padding-top: 2px;
  bottom: -15px;
}
.gallery-button {
  color: #616365;
  width: 32px;
  height: 32px;
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery-button.prev > img {
  height: 23px;
}
.gallery-button.next > img {
  height: 23px;
}
.gallery-button:hover {
  color: #616365;
  border: none;
}
.gallery-button:before {
  top: 25px;
  position: relative;
}
.pagination--gallery {
  bottom: -50px;
  text-align: center;
  padding: 0;
}
.gallery-navigation {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.gallery-element {
  width: 100%;
}
.gallery-element > .prev {
  transform: rotate(180deg);
}
.gallery-image {
  margin: 0 auto 0.625rem auto;
  display: table;
  width: 100%;
}
.gallery-image.-hidden {
  display: none;
}
.gallery-image > img {
  max-height: 31.25rem;
  width: 100%;
}
.lernbuch-content {
  position: relative;
  min-height: calc(100vh - 71px - 60px);
}
.lernbuch-content .chapter-element {
  padding-bottom: 1.25rem;
  page-break-inside: avoid;
}
.lernbuch-content .chapter-element ul,
.lernbuch-content .chapter-element ol {
  padding-left: 21px;
}
.lernbuch-content h1.chapter-element {
  padding-bottom: 1.875rem;
  padding-top: 1.875rem;
}
@media only screen and (max-width: small-break-point) {
  .lernbuch-content {
    min-height: calc(100vh - (71px + 120px));
    top: 7.5rem;
  }
  .lernbuch-content .chapter-element {
    padding-bottom: 0.9375rem;
  }
}
.bg-circle {
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  color: #616365;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
}
.bg-circle.-active {
  background-color: #f18e00;
  color: #fff;
}
.bg-circle.-middle {
  transform: translate(-50%, -50%);
}
.bg-circle.-small {
  width: 0.75rem;
  height: 0.75rem;
}
.fixed-page-layout.-lernspur {
  background-color: #202023;
}
.fixed-page-layout.-lernspur > .content {
  height: 100vh;
}
.fixed-page-layout.-lernspur > .content.-margin-right {
  margin-right: 23.9375rem;
}
.fixed-page-layout.-lernspur > .sidebar {
  width: 23.9375rem;
}
.fixed-page-layout.-lernspur > .sidebar .fresh {
  display: none;
}
.pagination-steps .bg-circle {
  transition: all 0.3s;
}
.pagination-steps > .step.-active .bg-circle {
  background-color: #f18e00;
}
.sidebar-content .element-layout {
  padding: 0;
}
.lernspur-step-overview > .shadow-box {
  transition: box-shadow 0.2s;
  cursor: pointer;
}
.lernspur-step-overview .item {
  transition: all 0.2s;
  opacity: 0;
  text-align: center;
  color: #fff;
  white-space: nowrap;
}
.lernspur-step-overview .item.bg {
  background-color: rgba(0,0,0,0.6);
}
.lernspur-step-overview .abs-layout {
  overflow: hidden;
}
.lernspur-step-overview .content {
  height: 7.8125rem;
}
.lernspur-step-overview:hover > .shadow-box {
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.17);
}
.lernspur-step-overview:hover .item {
  opacity: 1;
}
.tooltip-container {
  position: absolute;
  padding: 12px;
  z-index: 1000;
}
.tooltip-container > .content {
  background-color: rgba(0,0,0,0.8);
  border-radius: 3px;
  color: #ddd;
  position: relative;
}
.tooltip-container > .content:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: solid transparent;
  border-width: 6px;
}
.tooltip-container.-left {
  transform: translate(-100%, -50%);
}
.tooltip-container.-left > .content:before {
  border-left-color: rgba(0,0,0,0.8);
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.tooltip-container.-right {
  transform: translateY(-50%);
}
.tooltip-container.-right > .content:before {
  border-right-color: rgba(0,0,0,0.8);
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.tooltip-container.-top {
  transform: translate(-50%, -100%);
}
.tooltip-container.-top > .content:before {
  border-top-color: rgba(0,0,0,0.8);
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.tooltip-container.-bottom {
  transform: translateX(-50%);
}
.tooltip-container.-bottom > .content:before {
  border-bottom-color: rgba(0,0,0,0.8);
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.locale-link {
  text-transform: uppercase;
  display: inline-block;
}
.locale-link:not(.-active):hover {
  color: #f18e00;
}
.locale-link.-active {
  color: #616365;
  font-weight: bold;
}
.locale-link + .locale-link {
  margin-left: 0.5rem;
}
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0,0,0,0.4);
}
.chapter-element-icon {
  fill: rgba(241,144,2,0.8);
}
.chapter-element-icon.-fl-content {
  fill: rgba(134,188,37,0.8);
}
.navigation-chapter-element > .text {
  margin-bottom: 0.3125rem;
}
.navigation-chapter-element > .text:hover {
  color: #f18e00;
  cursor: pointer;
}
.navigation-chapter-element.-foldable {
  display: inline-block;
  margin-right: 0.625rem;
}
.navigation-chapter-element.-foldable:hover {
  cursor: pointer;
}
.navigation-chapter-element.-foldable:hover .chapter-element-icon {
  fill: #f19002;
}
.navigation-chapter-element.-foldable:hover .chapter-element-icon.-fl-content {
  fill: #86bc25;
}
.navigation-chapter-element.-wiwag-tipp {
  top: 4px;
  position: relative;
}
.navigation-chapter-element.-powerpoint svg {
  margin-bottom: -0.1875rem;
}
.navigation-chapter-element .chapter-element-icon {
  fill: rgba(241,144,2,0.8);
}
.navigation-chapter-element .chapter-element-icon.-fl-content {
  fill: rgba(134,188,37,0.8);
}
.chapter-elements {
  padding-left: 4.0625rem;
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
}
.chapter-elements ul {
  list-style-type: none;
}
.chapter-title {
  text-transform: uppercase;
  padding-left: 1.25rem;
  min-height: 3.9375rem;
}
.chapter-title:hover {
  color: #f18e00;
  cursor: pointer;
}
.chapter-title:hover .icon {
  fill: #f18e00;
}
.chapter-title > .icon {
  width: 2.1875rem;
  height: 2.1875rem;
  float: left;
  margin-top: 0.8125rem;
  margin-right: 0.9375rem;
  vertical-align: text-bottom;
  fill: #616365;
  padding-top: 0.375rem;
}
.home-link {
  color: #616365;
}
.home-link:hover {
  color: #f18e00;
}
.navigation-back > .link {
  width: 1.875rem;
  height: 1.875rem;
  background-image: url("../images/icon-arrow-back.svg");
  background-repeat: no-repeat;
  position: absolute;
  left: 1.25rem;
  top: 1.125rem;
  cursor: pointer;
}
.navigation-chapter-element > .text {
  color: #616365;
  margin-bottom: 0.3125rem;
  line-height: 1.4rem;
}
.navigation-chapter-element > .text:hover {
  cursor: pointer;
}
.navigation-chapter-element > ul {
  padding-left: 1rem;
}
.navigation-chapter-element.-foldable {
  display: inline-block;
  margin-right: 0.625rem;
}
.navigation-chapter-element.-foldable:hover {
  cursor: pointer;
}
.navigation-chapter-element.-powerpoint svg {
  margin-bottom: -0.1875rem;
}
.navigation-chapter-element.-current {
  background-color: #fff;
}
.navigation-chapter-element.-current > .text {
  font-weight: bold;
}
.navigation-chapter {
  position: relative;
  font-size: 1.0625rem;
  color: #616365;
  background-color: #fff;
  border-bottom: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
}
.navigation-chapter.-current > .chapter-title > .title {
  font-weight: bold;
}
.navigation-chapter > .chapter-title {
  padding-right: 3.4375rem;
}
.navigation-chapter > .chapter-title .icon {
  width: 1.875rem;
  margin-right: 1.25rem;
  vertical-align: text-bottom;
  fill: #616365;
  padding-top: 0.375rem;
}
.navigation-chapter > .chapter-title > .title {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  line-height: 2.1875rem;
}
.navigation-chapter > .chapter-elements {
  display: none;
}
.navigation-chapter.-open > .chapter-elements {
  display: block;
}
.navigation-chapter .navigation-dialog {
  display: none;
}
.navigation-chapter > .toggle {
  position: absolute;
  top: 0.75rem;
  right: 0.625rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-chapter > .toggle:hover {
  cursor: pointer;
}
.navigation-chapter ul {
  list-style-type: none;
  margin-left: 0.3125rem;
}
.navigation-chapter .-inverted {
  transform: rotateX(180deg);
}
.chapter-navigation > .chapters {
  position: fixed !important;
  top: 3.75rem;
  width: 26.875rem;
  height: calc(100vh - 60px);
  overflow-y: auto;
  border-top: 1px solid #d3d3d3;
}
@media only screen and (max-width: small-break-point) {
  .chapter-navigation > .chapters {
    width: 100vw;
    height: calc(100vh - 120px);
  }
}
.chapter-navigation.-open {
  background-color: initial;
}
.chapter-navigation.-open > .toggle > .-when-closed {
  display: none;
}
.chapter-navigation:not(.-open) > .toggle > .-when-open {
  display: none;
}
.paging-navigation {
  display: none;
}
.paragraph-element .image {
  padding-right: 1rem;
}
.paragraph-element ul,
.paragraph-element ol {
  margin: 1.375rem;
}
@media only screen and (max-width: large-break-point) {
  .paragraph-element .image {
    padding: 0;
  }
  .paragraph-element .paragraph {
    margin-top: 1rem;
  }
}
.print-header {
  display: none;
}
.search-input {
  z-index: -1;
}
.search-output {
  top: 3.8125rem;
  left: 0;
  max-width: 53.125rem;
  max-height: calc(100vh - 60px);
  padding: 1.25rem;
  background-color: #fff;
  border-bottom: 1px solid rgba(0,0,0,0.25);
  border-left: 1px solid rgba(0,0,0,0.25);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
  overflow-y: auto;
  display: none;
  position: fixed;
  z-index: 100;
}
.search-output.-open {
  display: block;
}
.search-output .chapter-title {
  padding-right: 1.25rem;
  padding-left: 0;
  float: left;
  color: #f18e00;
}
.search-output .excerpt {
  color: #616365;
  padding-bottom: 10px;
  cursor: pointer;
}
.search-output .excerpt .search-output__result--highlight {
  color: #616365;
  background-color: rgba(66,139,202,0.4);
}
.search-output .excerpt:hover {
  color: #f18e00;
}
.search-output .no-content {
  display: none;
}
.search-output.-no-results .no-content {
  display: block;
}
.start-animation {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: #fff;
  z-index: 5;
}
.start-animation.-animation {
  animation: fadeOutStartAnimation 1s forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-delay: 4.5s;
}
.start-animation > .wave {
  transform: scaleX(1.6) translateX(5%);
}
.start-animation > .wave.-animation {
  opacity: 1;
  animation-name: waveAnimation;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}
.start-animation > .logo {
  position: absolute;
  top: 110px;
  left: 30px;
  width: 380px;
  height: 24px;
}
.start-animation > .logo.-animation {
  animation: logoAnimation 2s forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
}
.start-animation > .claim {
  position: absolute;
  bottom: 200px;
  right: 90px;
  width: 400px;
  height: 37px;
  opacity: 0;
}
.start-animation > .claim.-animation {
  animation: claimAnimation 2s forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-delay: 1.5s;
}
@media only screen and (max-width: large-break-point) {
  .start-animation > .wave {
    transform: scaleX(1.6) translateY(23%) translateX(5%);
  }
  .start-animation > .logo {
    width: 300px;
    height: 19px;
  }
  .start-animation > .claim {
    width: 270px;
    height: 24px;
  }
}
@media only screen and (max-width: small-break-point) {
  .start-animation {
    top: 7.5rem;
  }
  .start-animation > .wave {
    transform: scaleX(1.9) scaleY(1.5) translateY(45%) translateX(5%);
  }
  .start-animation > .logo {
    top: 40px;
    left: 15px;
    width: 250px;
    height: 19px;
  }
  .start-animation > .claim {
    right: 15px;
    bottom: 220px;
    width: 200px;
    height: 18px;
  }
}
@-moz-keyframes fadeOutStartAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOutStartAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOutStartAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOutStartAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes waveAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes waveAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes waveAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes waveAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes logoAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes logoAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@-o-keyframes logoAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes logoAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@-moz-keyframes claimAnimation {
  0% {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1.5);
  }
  80% {
    transform: scale3d(1, 1, 1);
  }
  90% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes claimAnimation {
  0% {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1.5);
  }
  80% {
    transform: scale3d(1, 1, 1);
  }
  90% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@-o-keyframes claimAnimation {
  0% {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1.5);
  }
  80% {
    transform: scale3d(1, 1, 1);
  }
  90% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes claimAnimation {
  0% {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1.5);
  }
  80% {
    transform: scale3d(1, 1, 1);
  }
  90% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .start-animation > .wave {
    transform: scaleX(1.9) translateY(17%) translateX(3%);
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: large-break-point) {
  .start-animation > .wave {
    transform: scaleX(1.6) translateY(3%) translateX(4%);
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: small-break-point) {
  .start-animation > .wave {
    transform: scaleX(1.6) translateY(-7%) translateX(4%);
  }
}
.svg-icon {
  box-sizing: content-box;
}
.text-input {
  height: 2.5rem;
  border-radius: 1.25rem;
  border: 1px solid #ccc;
  padding-left: 0.9375rem;
  color: #616365;
  font-size: 1rem;
  outline: none;
}
.text-input.-search {
  padding-left: 2.1875rem;
  width: 12.5rem;
}
@media only screen and (max-width: small-break-point) {
  .text-input.-search {
    font-size: 1rem;
    width: 9.375rem;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
body,
blockquote {
  font-family: "Helvetica Neue LT", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  word-break: normal;
}
h1 {
  font-weight: bold;
  font-size: 3.625rem;
  color: #616365;
  line-height: 4.375rem;
  letter-spacing: 0.075rem;
}
h2 {
  font-size: 3rem;
  color: #616365;
  line-height: 3.625rem;
}
h3 {
  font-size: 1.875rem;
  letter-spacing: 0.078125rem;
  line-height: 46px;
}
a {
  color: #f18e00;
}
a:hover {
  color: #616365;
}
ul,
ol {
  padding: 0.625rem 0 0.625rem 1.25rem;
}
ul li + li,
ol li + li {
  padding-top: 0.3125rem;
}
blockquote {
  font-size: 2.25rem;
  line-height: 3.125rem;
  padding-bottom: 10px;
  color: #8cb612;
  margin-left: -70px;
}
blockquote + .caption {
  color: #8cb612;
  margin-left: -70px;
  font-size: 0.9375rem;
  line-height: 1.3125rem;
}
@media only screen and (max-width: 48rem) {
  h1 {
    font-size: 2.25rem;
    line-height: 2.625rem;
  }
  h2 {
    font-size: 1.875rem;
    line-height: 2.125rem;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}
.themenwaehler-iframe {
  width: 100vw;
  top: 3.75rem;
  position: relative;
  min-height: calc(100vh - 71px - 60px);
}
.themenwaehler-iframe.-animation {
  animation: fadeInThemenwaehler 1s forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-delay: 4.5s;
}
@media only screen and (max-width: small-break-point) {
  .themenwaehler-iframe {
    top: 7.5rem;
  }
}
@-moz-keyframes fadeInThemenwaehler {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeInThemenwaehler {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeInThemenwaehler {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInThemenwaehler {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.title-element {
  color: #f18e00;
  border-top: 2px solid #d3d3d3;
}
.video-element {
  max-width: 100%;
}
.video-element .caption {
  margin-top: 0.9375rem;
}
@media print {
  @page {
    size: A4 portrait;
    margin: 1cm 2cm 1cm 2cm;
  }
  .fixed-header,
  .foldable-element,
  .image-gallery,
  .gallery-navigation,
  .chapter-cover img {
    display: none;
  }
  .lernbuch-content {
    top: 1.875rem;
  }
  .print-header {
    display: flex;
    align-items: center;
  }
  .print-header >.logo {
    width: 2.875rem;
    height: 2.875rem;
  }
  .print-header >.wiwo {
    padding-left: 0.625rem;
    font-size: 1.0625rem;
    text-transform: uppercase;
  }
  h1.title {
    font-size: 1.5625rem;
    color: #616365;
    page-break-before: always;
  }
  h1.title.-first {
    page-break-before: avoid;
  }
  h1.title.-book {
    font-size: 2.8125rem;
  }
  h1 {
    font-size: 2.1875rem;
    color: #616365;
    line-height: 2.1875rem;
  }
  h2 {
    font-size: 2rem;
    color: #616365;
    line-height: 2.1875rem;
  }
  h3 {
    font-size: 1.75rem;
    color: #616365;
    line-height: 1.9375rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    page-break-after: avoid;
  }
  body {
    font-size: 0.75rem;
    line-height: 1.375rem;
  }
  .chapter-element {
    widows: 3;
    orphans: 3;
  }
  .print-hidden {
    display: none;
  }
  .cover-title {
    position: relative;
    top: 0;
    left: 0;
  }
  .cover-title > .title {
    font-size: 2.1875rem;
    color: #616365;
    padding: 2rem 1rem 0 1rem;
    margin: 0;
    max-width: none;
    line-height: initial;
    page-break-before: avoid;
  }
  .cover-dimmer {
    display: none;
  }
  .margin-box.-v-large {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .margin-box.-v-medium {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .margin-box.-v-small {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .margin-box.-bottom-large {
    margin-bottom: 10px;
  }
  .image-responsive {
    max-height: 250px;
    page-break-inside: avoid;
  }
  .gallery-image-list {
    page-break-inside: avoid;
  }
  .next-chapter {
    display: none;
  }
  .element-layout.-left > .float {
    float: left;
    padding-right: 1rem;
    padding-bottom: 1rem;
    max-width: 24.375rem;
  }
  .element-layout.-right > .float {
    float: right;
    padding-right: 1rem;
    padding-bottom: 1rem;
    max-width: 24.375rem;
  }
}
