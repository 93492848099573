.navigation-chapter {
    position: relative;
    font-size: rem(17px);

    color: text-color;
    background-color: body-color;

    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;

    &.-current {
        > .chapter-title > .title {
            font-weight: bold;
        }
    }

    > .chapter-title{
        padding-right: rem(55px); // make room for toggle
        .icon{
            width: rem( 30px );
            margin-right: rem( 20px );
            vertical-align: text-bottom;
            fill: text-header-color;
            padding-top: rem( 6px );
        }
        > .title {
            padding-top: rem(15px);
            padding-bottom: rem(15px);
            line-height: rem(35px);
        }
    }

    > .chapter-elements {
        display: none;
    }

    &.-open {
        > .chapter-elements {
            display: block;
        }    
    }

    .navigation-dialog {
        display: none;
    }

    > .toggle {
        position: absolute;
        top: rem(12px);
        right: rem(10px);
        width: rem(40px);
        height: rem(40px);

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover{
            cursor:pointer;
        }
    }

    ul{
        list-style-type: none;
        margin-left: rem( 5px );
    }

    .-inverted {
        transform: rotateX( 180deg )
    }
}
