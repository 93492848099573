.glossary-link {
    background-image: url('../images/icon-glossary.svg');
    width: rem(15px);
    height: rem(30px);
    background-repeat: no-repeat;
    position: absolute;
    right: rem(140px);
    top: rem(18px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    font-size: rem(14px);
    color: text-header-color;

    &:hover {
        background-image: url('../images/icon-glossary-hover.svg'); 
    }
}

.print-link {
    background-image: url('../images/icon-print.svg');
    width: rem(30px);
    height: rem(30px);
    background-repeat: no-repeat;
    display:inline-block;
    position:relative;
    top: rem( 9px )
    right: rem( 10px )

    &:hover {
        //background-image: url('../images/icon-print-hover.svg');    
    }
}

.feedback-link {
    background-image: url('../images/Button_Feedback.svg');
    width: rem(30px);
    height: rem(30px);
    background-repeat: no-repeat;
    display:inline-block;
    position:relative;
    top: rem( 9px )
    right: rem( 10px )
}

.login-link {
    background-image: url('../images/icon-lock.svg');
    width: rem(23px);
    height: rem(30px);
    background-repeat: no-repeat;
    position: absolute;
    right: rem(30px);
    top: rem(18px);
    &:hover {
        background-image: url('../images/icon-lock-hover.svg');    
    }
}

@media only screen and (max-width: small-break-point) { 
    .glossary-link {
        right: rem(110px);
        padding-left: 0;
        width: rem(20px);
    }

    .print-link {
        //right: rem(60px);
    }

    .login-link {
        right: rem(20px);
    }
}