.lernspur-step-overview {

  > .shadow-box {
    transition: box-shadow 0.2s;
    cursor: pointer;
  }

  .item {
    transition: all 0.2s;
    opacity: 0;
    text-align: center;
    color: theme.colors.text-inverted;
    white-space: nowrap;

    &.bg {
      background-color: rgba( 0, 0, 0, 0.6 );
    }
  }

  .abs-layout {
    overflow: hidden;
  }

  .content {
    height: rem( 125px );
  }

  &:hover {

    > .shadow-box {
      box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.17);
    }

    .item {
      opacity: 1;
    }
  }
}