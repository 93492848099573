.chapter {
    max-width: rem(637px + 30px + 30px); 
    margin: auto;
    padding-left: rem(30px);
    padding-right: rem(30px);

    @media only screen and (max-width: small-break-point) {
        padding-left: rem(20px);
        padding-right: rem(20px);
    }
}