.navigation-chapter-element{

    > .text{
        
        margin-bottom: rem( 5px );

        &:hover{
            color:action-color;
            cursor:pointer;
        }
    }

    &.-foldable{
        display: inline-block;
        margin-right: rem( 10px );

        &:hover{
            cursor:pointer;

            .chapter-element-icon{
                fill:foldable-orange-hover;
                
                &.-fl-content{
                    fill:foldable-green-hover;
                }
            }
        }

    }

    &.-wiwag-tipp {
        top: 4px;
        position: relative;
    }

    &.-powerpoint{
        svg{
            margin-bottom: rem( -3px );
        }
    }

    .chapter-element-icon{

        fill:foldable-orange;

        &.-fl-content{
            fill:foldable-green;

        }

    }
}