.mobile-title {
    height: rem(60px);
    width: 100vw;
    padding: rem(10px);
    font-size: rem(15px);
    border-bottom: 1px solid lightgray;

    text-align: center;

    .logo {
        text-align: center

        > a {
            font-size: rem(21px)
            line-height: rem(41px)
            color: text-color

            &:hover {
                color: main-color
            }
        }
    }

}