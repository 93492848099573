.image-element {

    > .image {
        margin: 0 auto;
    }

    .caption, .caption p {
        font-size: rem(15px)
        text-align: center;
        font-weight: bold;
        color: #5a5a5a;
        margin-top: 5px;
    }

    margin-top: rem( 40px );
    margin-bottom: rem( 40px );

    &.-screen > .bg {
        background-color: rgba( 204, 204, 204, 0.5 );

        > .image {
            margin: 0 auto;
        }
    }
}