.search-output {
    top: rem(61px);
    left: 0;
    max-width: rem(850px);
    max-height: calc(100vh - 60px);
    padding: 1.25rem;
    background-color: body-color;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    border-left: 1px solid rgba(0,0,0,0.25);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.30);
    overflow-y: auto;
    display: none;
    position: fixed;
    z-index: 100;

    &.-open {
        display: block;
    }

    .chapter-title {
        padding-right: rem(20px);
        padding-left: 0;
        float:left;
        color: text-hover-color;
    }

    .excerpt {
        color: text-color;
        padding-bottom: 10px;
        cursor: pointer;

        .search-output__result--highlight {
            color: text-color;
            background-color: rgba(66,139,202,0.4)
        }

        &:hover {
            color: text-hover-color;
        }
    }

    .no-content {
        display: none;    
    }

    &.-no-results {
        .no-content {
            display: block;    
        }
    }

    
}
