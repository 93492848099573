.fixed-header {
    position:fixed;
    height: rem(60px);
    width: 100%;
    background-color: header-background;
    z-index: 10;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.30);
    top:0;

    .search-box {
        height: 39px;    
        width: 39px;    
    }
}