.glossary-switch {
    ol {
        li {
            a {
                text-decoration: none;
                color: black;
            }
            &.selected {
                a {
                    color: white;
                }
            }
        }
    }
}
