.book-chapter {
	
	> .disclaimer {
		display: none;
	}

	@media print {
		&.-confidential {
			> .disclaimer {
				display: block;
			}

			> .elements {
				display: none;
			}

			> .next-chapter {
				display: none;
			}
		}
	}
}