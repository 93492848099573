.chapter-title{
    text-transform: uppercase;
    padding-left: rem( 20px );
    min-height: rem( 63px );

    &:hover{
        color: action-color;
        cursor: pointer;

        .icon{
            fill: action-color;
        }

    }

    > .icon{
        width: rem( 35px );
        height: rem( 35px );
        float: left;
        margin-top: rem( 13px );
        margin-right: rem( 15px );
        vertical-align: text-bottom;
        fill: text-header-color;
        padding-top: rem( 6px );
    }
}