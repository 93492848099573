.form-element {
    label {
        font-size: rem( 12px )
    }
    input, textarea {
        border: 1px solid #000;
        display: block;
        width: 100%;
        padding: rem( 4px )
    }

    .invalid-message {
        display:none;
        color: action-color
    }

    &.-invalid {
        .valid-message {
            display: none;
        }
        .invalid-message {
            display: block;
        }
    }
}