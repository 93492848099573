.bg-circle {

	width: rem( 32px );	
	height: rem( 32px );
	background-color: #FFF;
	color: text-color;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.3s;

	@extend .flex-layout
	@extend .flex-layout.-center

	&.-active {
		background-color: action-color;
		color: #FFF;
	}

	&.-shadow {
		@extend .shadow-box;	
	}

	&.-middle {
		transform: translate( -50%, -50% );	
	}

	&.-small {
		width: rem( 12px );	
		height: rem( 12px );
	}
}