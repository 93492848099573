.start-animation {
    width: 100vw; 
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: body-color;
    z-index: 5;

    &.-animation {
        animation: fadeOutStartAnimation 1s forwards;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-delay: 4.5s;
    }

    > .wave {
        transform: scaleX(1.6) translateX(5%);

        &.-animation {
            opacity: 1;
            animation-name: waveAnimation;
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-duration: 2s;
        }
    }

    > .logo {
        position: absolute;
        top: 110px;
        left: 30px;
        width: 380px;
        height: 24px;

        &.-animation {
            animation: logoAnimation 2s forwards;
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
        }

    }

    > .claim {
        position: absolute;
        bottom: 200px;
        right: 90px;
        width: 400px;
        height: 37px;
        opacity: 0;

        &.-animation {
            animation: claimAnimation 2s forwards;
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-delay: 1.5s;
        }
    }

    @media only screen and (max-width: large-break-point) {
        > .wave {
            transform: scaleX(1.6) translateY(23%) translateX(5%);
        }

        > .logo {
            width: 300px;
            height: 19px;
        }

        > .claim {
            width: 270px;
            height: 24px;
        }

    }

    @media only screen and (max-width: small-break-point) {
        top: rem(120px);

        > .wave {
            transform: scaleX(1.9) scaleY(1.5) translateY(45%) translateX(5%);
        }

        > .logo {
            top: 40px;
            left: 15px;
            width: 250px;
            height: 19px;
        }

        > .claim {
            right: 15px;
            bottom: 220px;
            width: 200px;
            height: 18px;
        }

    }

    @keyframes fadeOutStartAnimation {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes waveAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes logoAnimation {
        0% {
            opacity: 0;
            // transform: translate3d(0,125%,0);
        }
        100% {
            opacity: 1;
            transform: none;
        }
    }

    @keyframes claimAnimation {
        0% {
            opacity: 0;
            transform: scale3d(1.5, 1.5, 1.5);
        }
        80% {
            transform: scale3d(1, 1, 1);
        }
        90% {
            transform: scale3d(1.1, 1.1, 1.1);
        }
        100% {
            opacity: 1;
            transform: none;
        }
    }
}

// IE-Only: Hack Media Query
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .start-animation {
        > .wave {
            transform: scaleX(1.9) translateY(17%) translateX(3%);
        }
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: large-break-point) {
    .start-animation {
        > .wave {
            transform: scaleX(1.6) translateY(3%) translateX(4%);
        }
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (max-width: small-break-point) {
    .start-animation {
        > .wave {
            transform: scaleX(1.6) translateY(-7%) translateX(4%);
        }
    }
}