.pagination-steps {
	
	@extends .flex-layout	
	@extends .flex-layout.-row

	.bg-circle  {
		transition: all 0.3s;
	}

	> .step.-active {
		.bg-circle {
			background-color: action-color;
		}	
	}	
}