.chapter-navigation {
  > .chapters {
    position: fixed !important;
    top: rem(60px);
    width: rem(430px);
    height: calc(100vh - 60px);
    @media only screen and (max-width: small-break-point) {
      width: 100vw;
      height: calc(100vh - 120px);
    }
    overflow-y: auto;
    border-top: 1px solid lightgray;
  }

  &.-open {
    background-color: initial;
    > .toggle > .-when-closed {
      display: none;
    }
  }

  &:not(.-open) {
    > .toggle > .-when-open {
      display: none;
    }
  }
}
