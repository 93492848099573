.glossary-list {
    max-width: rem(850px); 
    top: rem(60px);
    position: relative;
    min-height: calc(100vh - 71px - 60px);
    padding-left: rem(30px);
    padding-right: rem(30px);
    padding-top: rem(60px);

    > .letter {
        padding-top: rem(110px);
        padding-bottom: rem(50px);
        background-color: @body-color;
        font-size: rem(36px);
    }

    h1 {
        padding-bottom: rem(40px);
    }

    @media only screen and (max-width: small-break-point) {
        top: rem(180px);
    }
}