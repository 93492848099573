.navigation-chapter-element{

    > .text {
        
        color: text-color
        margin-bottom: rem( 5px );
        line-height: 1.4rem;

        &:hover{
            //color: white;
            cursor:pointer;
        }
    }

    > ul {
        padding-left: 1rem;
    }

    &.-foldable{
        display: inline-block;
        margin-right: rem( 10px );

        &:hover{
            cursor:pointer;
        }

    }

    &.-powerpoint{
        svg{
            margin-bottom: rem( -3px );
        }
    }

    &.-current {

        background-color: body-color;

        > .text {
            font-weight: bold;
        }
    }

}