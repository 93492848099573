.cover-title {

    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    
    > .title {
        font-family: 'HelveticaRoundedLTStd-Bd';
        padding: rem( 20px ) rem(50px);
        max-width: rem( 780px );
        color: white;
        font-size: rem(64px);
        line-height: rem(70px);
        letter-spacing: rem(1px);
    }

    @media only screen and (max-width: theme.settings.breakpoints.small ) {

        > .title {
            font-size: rem(28px);
            line-height: rem(32px);
            padding: rem( 5px ) rem(10px);
        }
    }
}