@media print {

    @page {
        size: A4 portrait;
        margin: 1cm 2cm 1cm 2cm;
    }

    // hide stuff
    .fixed-header, .foldable-element, .image-gallery, .gallery-navigation, .chapter-cover img {
        display: none;
    }

    .lernbuch-content {
        top: rem(30px);
    }

    .print-header {
        display: flex;
        align-items: center;

        >.logo {
            width: rem(46px);
            height: rem(46px);
        }

        >.wiwo {
            padding-left: rem(10px);
            font-size: 1.0625rem;
            text-transform: uppercase;
        }
    }

    h1.title {
        font-size: rem(25px);
        color: text-color;
        page-break-before: always;

        &.-first {
            page-break-before: avoid;
        }

        &.-book {
            font-size: rem(45px);
        }
    }


    h1 {
        font-size: rem(35px);
        color: text-color;
        line-height: rem(35px);
    }

    h2 {
        font-size: rem(32px);
        color: text-color;
        line-height: rem(35px);
    }

    h3 {
        font-size: rem(28px);
        color: text-color;
        line-height: rem(31px);
    }

    h1, h2, h3, h4, h5 {
        page-break-after: avoid;
    }

    body {
        font-size: rem(12px);
        line-height: rem(22px);
    }

    .chapter-element{
        widows: 3;
        orphans: 3; 
    }

    .print-hidden {
        display: none;
    }

    .cover-title {
        position: relative;
        top: 0;
        left: 0;

        > .title {
            font-size: rem(35px);
            color: text-color;
            padding: rem( 32px ) rem( 16px ) 0 rem( 16px );
            margin: 0;
            max-width: none;
            line-height: initial;
            page-break-before: avoid;
        }
    }

    .cover-dimmer {
        display: none;    
    }

    .margin-box {

        &.-v-large {
            margin-top: 10px;
            margin-bottom: 10px;    
        }
        
        &.-v-medium {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        &.-v-small {
            margin-top: 3px;
            margin-bottom: 3px;
        }

        &.-bottom-large {
            margin-bottom: 10px;
        }
    }

    .image-responsive {
        max-height: 250px;
        page-break-inside: avoid;    
    }

    .gallery-image-list {
        page-break-inside: avoid;    
    }

    .next-chapter {
        display: none;    
    }

    .element-layout.-left > .float {
        float: left;
        padding-right: 1rem;
        padding-bottom: 1rem;
        max-width: 24.375rem;
    }

    .element-layout.-right > .float {
        float: right;
        padding-right: 1rem;
        padding-bottom: 1rem;
        max-width: 24.375rem;
    }


}