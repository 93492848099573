.navigation-back {
    > .link {
        width: rem(30px);
        height: rem(30px);
        background-image: url('../images/icon-arrow-back.svg');
        background-repeat: no-repeat;
        position: absolute;
        left: rem(20px);
        top: rem(18px);
        cursor: pointer;
    }
}