.glossary-item {
    border-top: 1px solid @text-color;

    > .title {
        padding-top: rem(25px);
        padding-bottom: rem(25px);
        background-color: @body-color;
        font-size: rem(24px);
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        >.arrow {
            background: url("../images/icon-arrow-up.svg");
            background-repeat: no-repeat;
            height: rem(12px);
            width: rem(20px);
        }

        &.-closed {
            > .arrow {
                background: url("../images/icon-arrow-down.svg");
            }
        }
    }

    > .description {
        padding-bottom: rem(25px);
        font-size: rem(22px);
        background-color: @body-color;
    }
}