/**
 * Abs Class:
 * `.abs` - Use this class set the position of an element to absolute.
 * 
 * 	@example
 * 	div.abs
 */
.abs {
	position: absolute;
}

.hidden {
	display: none;
	visibility: hidden;
}