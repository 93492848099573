.locale-link {
	text-transform: uppercase;
	display: inline-block

	&:not(.-active):hover {
		color: action-color;
	}

	&.-active {
		color: text-color;
		font-weight: bold;
	}
}

.locale-link + .locale-link {
	margin-left: rem(8px);
}
