.chapter-element {

    position: relative;
    
    .fresh {
        position: absolute;
        left: -60px;
        transform: rotate(-27deg);
        color: #f18e00;
        top: 5px;
        text-transform: lowercase;
        line-height: 23px;
        font-size: 13px;
        letter-spacing: 1px;

        &::first-letter {
            border-bottom: 1px solid #f18e00;
        }
    }

    > p {
        padding: rem( 5px ) 0;
    }
}